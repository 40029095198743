import React, { useContext } from 'react';
import { ContainerTemplate, Footer, Header, Img, LinkTitle, LogoContainer, UserArea, UserContainer, UserName } from './styles';
import { UserContext } from '../contexts/user/UserProvider';
import Button from '../components/Button';
import Icon from '../components/Icon';
import { useHistory } from 'react-router-dom';

function TemplateRoute({ children }) {
  const { user, logOut } = useContext(UserContext);
  let history = useHistory();

  const deslogar = () => {
    logOut();
    history.push('/login');
  }

  const home = () => {
    history.push('/home');
  }
  return (
    <>
      <ContainerTemplate>
        <Header>
          <LogoContainer to='/'>
            {user && (
              <Button
                minify
                rounded
                color='title'
                click={home}
              >
                <Icon iconName='FaHome' size={20} color='light' />
              </Button>
            )}
            <LinkTitle to='/'>
              <Img noMargin smaller src='/IncentivaMais.svg'  />
            </LinkTitle>
          </LogoContainer>
          {user && <div>
            <UserArea>
              <UserContainer>
                <UserName>
                  {user.nomeUsuario.split(' ')[0]}
                </UserName>
              </UserContainer>
              <Button
                color='danger'
                click={deslogar}
                minify
                rounded
              >
                <Icon iconName='FaPowerOff' size={12} color='light' />
              </Button>
            </UserArea>
          </div>}
          {!user &&
            <div>
              <Button
                color='light'
                click={deslogar}
                minify
                rounded
              >
                <Icon iconName='BsFillPersonFill' bs color='title' />
              </Button>
            </div>}
        </Header>
        {children}
        <Footer>
          <Img src='/logo-sgi.png' />
          <Img src='/fundtur.png' />
          <Img src='/Trio_de_Logos_FundTur_Semagro_GOV_PNG.png' />
        </Footer>
      </ContainerTemplate>
    </>
  );
}

export default TemplateRoute;