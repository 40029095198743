import styled from 'styled-components';

export const Container = styled.div`
  min-height: calc(100vh - 180px);
  margin: 80px 0 100px 0;
`;

export const ButtonArea = styled.div`
  margin:20px 10%;
  display: flex;
  align-items: center;
`

export const Title = styled.h3`
  text-transform: capitalize;
  margin: 0 10px;
`
