import Api from '../functions/api';
import { serialize } from '../functions/objectToQuery';

const getAll = () => {
  return Api.get(`/Banco`);
}

const get = (params) => {
  return Api.get(`/Bancos?${serialize(params)}`);
}

const getByCodigo = (codigo) => {
  return Api.get(`/Bancos/${codigo}`);
}

const create = (body) => {
  return Api.post(`Bancos`, body);
}

const put = (id, body) => {
  return Api.put(`Bancos/${id}`, body);
}

const ActiveOrInactive = (id, active) => {
  return Api.put(`Bancos/${active ? 'ativar' : 'desativar'}/${id}`)
}

const methods = {
  getAll,
  get,
  getByCodigo,
  create,
  put,
  ActiveOrInactive
}

export default methods;