import Api from '../functions/api';

const authenticate = (body) => {
  return Api.post('/Authentication/Login', body);
}

const domains = () =>{
  return Api.get("/Authentication/Dominios");
}

const renew = () =>{
  return Api.get("/Authentication/Renew");
}

const authenticateTaH = (body) => {
  return Api.post('/Authentication/LoginGSI',body);
}

const methods = {
  authenticate,
  authenticateTaH,
  domains,
  renew
}

export default methods;