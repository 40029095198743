import styled from 'styled-components';
import Select from 'react-select'

export const InputArea = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
`

export const Label = styled.span`
  color: ${props => props.theme.PRIMARY};
`

export const FocusBorder = styled.span`
  position: absolute;
  bottom: 0;
  left: 0;
  width: ${props => props.error ? '100%' : 0};
  height: 2px;
  background-color: ${props => props.error ? props.theme.DANGER : props.theme.PRIMARY};
  transition: 0.4s;
`

export const TextError = styled.span`
  color: ${props => props.theme.DANGER};
`

export const SelectStyled = styled(Select)`
  &:focus-within ~ ${FocusBorder}{
    width: 100%;
  }
`