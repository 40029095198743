import Api from '../functions/api';
import { serialize } from '../functions/objectToQuery';

const getAll = () => {
  return Api.get('/Cnae');
}

const get = (params) => {
  return Api.get(`/Cnaes?${serialize(params)}`);
}

const getById = (id) => {
  return Api.get(`/Cnaes/${id}`);
}

const create = (body) => {
  return Api.post(`Cnaes`, body);
}

const put = (id, body) => {
  return Api.put(`Cnaes/${id}`, body);
}

const ActiveOrInactive = (id, active) => {
  return Api.put(`Cnaes/${active ? 'ativar' : 'desativar'}/${id}`)
}

const methods = {
  getAll,
  get,
  getById,
  create,
  put,
  ActiveOrInactive
}

export default methods;