import React from 'react';
import { CircleLoading  } from 'react-loadingg';
import { lightTheme } from '../../styles/theme';
import { Container } from './styles';
import { usePromiseTracker } from "react-promise-tracker";

function Loading() {
  const { promiseInProgress } = usePromiseTracker();
  return (promiseInProgress && 
    <Container>
      <CircleLoading color={lightTheme['PRIMARY']} />
    </Container>
  );
}

export default Loading;