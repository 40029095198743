import { Form } from '@unform/web';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const Content = styled.div`
  width: 80%;
  background-color: ${props => props.theme.LIGHT};
  border-radius: 5px;
  overflow: hidden;
`

export const CardTitle = styled.div`
  background-color: ${props => props.pj ? props.theme.EMPRESA : props.theme.GUIA};
  color: ${props => props.pj ? props.theme.LIGHT : props.theme.DARK};
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  width: 100%;
  text-align: center;
  padding: 15px;
  text-transform: uppercase;
  box-sizing: border-box;
  display: flex;
  justify-content:center;
  align-items: center;
`

export const Title = styled.h4`
  text-transform: capitalize;
  margin: 0 10px;
`

export const CardBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px 25px;
  overflow-wrap: break-word;
  white-space: break-spaces;
`

export const NameText = styled.h4`
  color: ${props => props.theme.TEXT_GRAY};
  font-weight: 900;
  margin: 15px 5px;
`

export const FieldText = styled.h4`
  color: ${props => props.theme.TEXT_GRAY};
  font-weight: 300;
  margin: 5px 20px;
`

export const FieldLink = styled.a`
  color: ${props => props.theme.TEXT_GRAY};
  font-weight: 300;
  margin: 5px 20px;
  text-decoration: underline;
  cursor: pointer;
`

export const StatusArea = styled.div`
  display: flex;
  justify-content: space-between;
`

export const Status = styled.h3`
  color: ${props => props.deferido ? props.theme.SUCCESS : props.theme.DANGER};
  display: flex;
`

export const Divider = styled.hr`
  width: 100%;
  border: none;
  border-top: solid 1px ${props => props.theme.BACKGROUND};
  margin: 10px 0;
`

export const ButtonArea = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin: 10px 0;
`

export const CardButton = styled.button`
  display:flex;
  align-items: center;
  padding: 20px;
  border: none;
  border-radius: 5px;
  text-transform: uppercase;
  background-color: ${props => props.theme[props.colorBg?.toUpperCase()]};
  color: ${props => props.theme.LIGHT};
  font-weight: 900;
  cursor: pointer;
`

export const TextButton = styled.div`
  margin: 0 10px;
`

export const FormStyled = styled(Form)`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
  padding: 0 20px;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: flex-start;
`

export const DefText = styled.div`
  display:flex;
  align-items:center;
  margin-left: 10px;
`