import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: ${props => props.theme.PRIMARY};
`;

export const RadiosContainer = styled.div`
  display: flex;
  /* flex-direction: column; */
  width: fit-content;
`

export const RadioContainer = styled.span`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 5px 5px;
`

export const RadioLabel = styled.label`
  margin: 0 5px;
`

export const TextError = styled.span`
  color: ${props => props.theme.DANGER};
`