import Api from '../functions/api';
import { serialize } from '../functions/objectToQuery';

const getPaginated = (params) => {
  return Api.get(`/Inscricao?${serialize(params)}`);
}

const get = (id) => {
  return Api.get(`/Inscricao/${id}`);
}

const getProtocolo = (protocolo) => {
  return Api.get(`/Inscricao/protocolo/${protocolo}`);
}

const deferir = (id) => {
  return Api.put(`/Inscricao/deferir/${id}`,{});
}

const indeferir = (id,motivo) => {
  return Api.put(`/Inscricao/indeferir/${id}`,{motivo: motivo});
}

const methods = {
  getPaginated,
  get,
  deferir,
  indeferir,
  getProtocolo
}

export default methods;