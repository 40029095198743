import styled from 'styled-components';

export const InputArea = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
`

export const Label = styled.span`
`

export const FocusBorder = styled.span`
  position: absolute;
  bottom: 0;
  left: 0;
  width: ${props => props.error ? '100%' : 0};
  height: 2px;
  background-color: ${props => props.error ? props.theme.DANGER : props.theme.PRIMARY};
  transition: 0.4s;
`

export const InputField = styled.textarea`
  font: 15px/24px "Lato", arial, sans-serif;
  color: ${props => props.theme.DARK};
  width: 600px;
  height: 250px;
  min-width: 400px;
  box-sizing: border-box;
  letter-spacing: 1px;
  border: 1px solid ${props => props.theme.PRIMARY};
  border-radius: 5px;
  background-color: ${props => props.theme.LIGHT_DARKER};
  padding: 2px 20px 2px 10px;

  &:focus {
    outline: none;
    outline-offset: 0px;
  }

  &:focus ~ ${FocusBorder}{
    width: 100%;
  }

  &:disabled {
    opacity: 0.7;
  }
`

export const TextError = styled.span`
  color: ${props => props.theme.DANGER};
`

export const ContainerInput = styled.div`
  display: flex;
  width: 100%;
`

export const ConteinerIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
`