import React, { useCallback, useEffect, useRef, useState } from 'react';
import Button from '../../components/Button';
import DataTable from '../../components/DataTable';
import Input from '../../components/Input';
import Modal from '../../components/Modal';
import TemplatePage from '../../components/TemplatePage';
import { Container, Content, FormStyled, InputContainer, Title } from './styles';
import EdicaoStore from '../../stores/EdicaoStore';
import { toast } from 'react-toastify';
import moment from 'moment';

function Edicoes() {
  const formRef = useRef(null);
  const [edicoes, setEdicoes] = useState([]);
  const [filtros] = useState({});
  const [totalPaginas, setTotalPaginas] = useState(0);
  const [totalRegistro, setTotalRegistro] = useState(0);
  const [param, setParam] = useState({ pagina: 1, registroPorPagina: 15, ordernarPor: 'desc' });
  const [crud, setCrud] = useState({ title: 'Criar edição', disabled: false, buttonText: 'Criar', create: true, id: 0 });
  const [modalOptions, setModalOptions] = useState({ open: false });

  const colunas = [
    { name: '', property: 'id', sortable: false, width: '5%' },
    { name: 'Inicio', property: 'inicio', width: '40%', sortable: false, date: true },
    { name: 'Término', property: 'termino', sortable: false, width: '40%', filtered: true, date: true },
  ];

  const getEdicao = useCallback(async (id) => {
    await EdicaoStore.getById(id).then(response => {
      if (response.status === 200) {
        formRef.current.setFieldValue('inscricao.inicio', moment(response.data.periodo.inscricao.inicio).format('yyyy-MM-DD'))
        formRef.current.setFieldValue('inscricao.termino', moment(response.data.periodo.inscricao.termino).format('yyyy-MM-DD'))
        formRef.current.setFieldValue('habilitacao.inicio', moment(response.data.periodo.habilitacao.inicio).format('yyyy-MM-DD'))
        formRef.current.setFieldValue('habilitacao.termino', moment(response.data.periodo.habilitacao.termino).format('yyyy-MM-DD'))
      }
      else {
        toast.error(response.data.mensagem);
      }
    })
  }, [])

  const viewEdicao = useCallback(async (edicao) => {
    await getEdicao(edicao.id);
    await setCrud({ title: `Visualizar edição ${edicao.id}`, disabled: true, buttonText: '', create: false, id: edicao.id })
  }, [getEdicao]);

  const editEdicao = useCallback(async (edicao) => {
    await getEdicao(edicao.id);
    await setCrud({ title: `Editar edição ${edicao.id}`, disabled: false, buttonText: 'Editar', create: false, id: edicao.id })
  }, [getEdicao]);

  const openModal = (edicao) => {
    setModalOptions({ open: true, edicao: edicao })
  }

  const acao = [
    { text: '', iconName: 'FaRegEye', color: 'background', click: viewEdicao, return: true, tooltip: 'Visualizar' },
    { text: '', iconName: 'FaEdit', color: 'warning', iconColor: 'dark', click: editEdicao, return: true, tooltip: 'Editar' },
    { text: '', iconName: 'FaTimes', color: 'danger', iconColor: 'light', click: openModal, return: true, conditionalStatus: true, tooltip: 'Inativar' },
    { text: '', iconName: 'FaCheck', color: 'success', iconColor: 'light', click: openModal, return: true, conditionalStatus: false, tooltip: 'Ativar' },
  ];

  function handlePageChange(params) {
    if (param.pagina === params)
      return;
    setParam({ ...param, pagina: params });
  }

  const cancelButton = useCallback(() => {
    formRef.current.reset();
    setCrud({ title: 'Criar edição', disabled: false, buttonText: 'Criar', create: true, id: 0 });
  }, [formRef])

  useEffect(() => {
    const call = async () => {
      await EdicaoStore.getEdicao({ ...param, ...filtros }).then(response => {
        if (response.status === 200) {
          setEdicoes(response.data.lista);
          setTotalPaginas(response.data.totalDePaginas);
          setTotalRegistro(response.data.totalDeRegistros);
        }
        else {
          toast.error(response.data.mensagem);
        }
      })
    }

    call();
  }, [filtros, param])

  const submit = useCallback(async (data) => {
    if (crud.create) {
      await EdicaoStore.create(data).then(response => {
        if (response.status === 201) {
          cancelButton();
          toast.success(response.data.mensagem);
          setParam({ ...param, pagina: 1 });
        }
        else
          toast.error(response.data.mensagem);
      })
    }
    else {
      await EdicaoStore.put(crud.id, data).then(response => {
        if (response.status === 200) {
          cancelButton();
          toast.success(response.data.mensagem);
          setParam({ ...param, pagina: 1 });
        }
        else
          toast.error(response.data.mensagem);
      })
    }

  }, [crud, cancelButton, param])

  const ativarOuInativar = useCallback(async (edicao) => {
    EdicaoStore.ActiveOrInactive(edicao.id, !edicao.ativo).then(response => {
      if (response.status === 200) {
        toast.success(response.data.mensagem);
        setParam({ ...param, pagina: 1 })
      }
      else
        toast.error(response.data.message);

      setModalOptions({ open: false });
    })
  }, [param])

  const buttonsModalInativar = [
    <Button text="cancelar" click={() => setModalOptions({ open: false })} color="background"></Button>,
    <Button text="Inativar" click={() => ativarOuInativar(modalOptions.edicao)} color="danger"></Button>,
  ];

  const buttonsModalAtivar = [
    <Button text="cancelar" click={() => setModalOptions({ open: false })} color="background"></Button>,
    <Button text="Ativar" click={() => ativarOuInativar(modalOptions.edicao)} color="success"></Button>,
  ];

  return (
    <TemplatePage title='Edições'>
      <Container>
        <Content>
          <FormStyled ref={formRef} onSubmit={submit}>
            <Title>{crud.title}</Title>
            <h4>Inscrições</h4>
            <InputContainer>
              <Input name='inscricao.inicio' disabled={crud.disabled} type='date' label='Data inicio' min={crud.create ? moment().format('yyyy-MM-DD') : null} />
            </InputContainer>
            <InputContainer>
              <Input name='inscricao.termino' disabled={crud.disabled} type='date' label='Data fim' min={crud.create ? moment().format('yyyy-MM-DD') : null} />
            </InputContainer>
            <h4>Habilitação</h4>
            <InputContainer>
              <Input name='habilitacao.inicio' type='date' disabled={crud.disabled} label='Data inicio' min={crud.create ? moment().format('yyyy-MM-DD') : null} />
            </InputContainer>
            <InputContainer>
              <Input name='habilitacao.termino' type='date' disabled={crud.disabled} label='Data fim' min={crud.create ? moment().format('yyyy-MM-DD') : null} />
            </InputContainer>
            {!crud.title.includes('Visualizar') && <Button text={crud.buttonText} type='submit' />}
            {!crud.create && <Button text='cancelar' color='background' click={cancelButton} />}
          </FormStyled>
          <DataTable
            columns={colunas}
            actions={acao}
            data={edicoes}
            pageChange={handlePageChange}
            allPages={totalPaginas}
            allRegister={totalRegistro}
            rowNumber={param.registroPorPagina}
            handleSort={(column, desc) => setParam({ ...param, ordernarPor: desc })}
            desc={param.ordernarPor}
            page={param.pagina}
          />
          {modalOptions.open && <Modal title={`${modalOptions.edicao.ativo ? 'Inativar' : 'Ativar'} edição`} actions={modalOptions.edicao.ativo ? buttonsModalInativar : buttonsModalAtivar}>
            <span>Deseja {modalOptions.edicao.ativo ? 'inativar' : 'ativar'} esta edição?</span>
          </Modal>}
        </Content>
      </Container>
    </TemplatePage>
  );
}

export default Edicoes;