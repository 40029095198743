import React, { useEffect, useRef } from 'react';
import { useField } from '@unform/core';
import { InputArea, Label, SelectStyled, TextError } from './styles';

const customStyles = {
  option: (provided, state) => {
    return { ...provided }
  },
  container: (provided, state) => {
    const retorno = {
    }
    return { ...provided, ...retorno }
  },
  control: (provided, state) => {
    const retorno = {
      display: 'flex',
      background: state.isDisabled ? 'rgba(0, 150, 150, 0.08)' : '#ededed',
      border: '1px solid #374c97',
      padding: '3px 0 2px 0',
      borderRadius: '5px',
      '&:focus': {
        backgroundColor: 'e1ffff',
        outline: 'none',
      },
      height: 28,
      font: '400 13.3333px Arial',
    }
    return { ...retorno }
  },
  singleValue: (provided, state) => {
    const opacity = 1;
    const transition = 'opacity 300ms';

    return { ...provided, opacity, transition };
  },
  menu: (provided, state) => {
    const retorno = {
      zIndex: 1000,
    }
    return { ...provided, ...retorno };
  },
  valueContainer: (provided, state) => {
    const retorno = {
      ':focus': {
        outline: 'none'
      }
    }
    return { ...provided, ...retorno };
  },
  dropdownIndicator: (provided) => {
    const retorno = {
      padding: '6px'
    }
    return { ...provided, ...retorno };
  }
}

export default function SelectSearch({ name, placeholder, label, dataSelect, setValue, disabled = false, multi = false, isClearable = false }) {
  const inputRef = useRef(null);
  const { fieldName, registerField, error, defaultValue } = useField(name);

  const change = (event) => {
    if (event === null) {
      if (setValue) setValue(null);
      return;
    };
    if (setValue)
      setValue(event?.value);

    inputRef.current.value = event?.value
  };

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      getValue: (ref) => {
        if (!ref.state.value) {
          return '';
        }
        return multi ? ref.state.value.map(r => r.value) : ref.state.value.value;
      },
      setValue: (ref, value) => {
        if (multi) {
            ref.state.value = [...dataSelect.map(x => {
            if (value.includes(x.value))
              return x
            else
              return null
          }).filter(Boolean)]
          change(ref.state.value)
        }
        else {
          ref.state.value = dataSelect.filter(x => x.value === value)[0] //dataSelect.filter(x => x.value === value)[0].label
          change(dataSelect.filter(x => x.value === value)[0])
        }
      }
    })
  }, [fieldName, registerField]);

  return (
    <>
      <InputArea>
        {label ?
          <Label>
            {label}
          </Label> :
          null
        }
        <SelectStyled
          defaultValue={defaultValue}
          ref={inputRef}
          onChange={change}
          isDisabled={disabled}
          options={dataSelect}
          styles={customStyles}
          placeholder='Selecione'
          isMulti={multi}
          isClearable={isClearable}
        />
      </InputArea>
      {error && <TextError>{error}</TextError>}
    </>
  )
}