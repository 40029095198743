import React, { useState, useEffect, useCallback } from 'react';
import Icon from '../Icon';
import { ActionArea, ButtonPagination, ColorTd, Container, CounterArea, PagesArea, PaginationArea, Table, TableHead, TableRow, TableRowBody, Td, TopArea } from './styles';
import Button from '../Button';
import moment from 'moment';
import { cnpjMask, cpfMask } from '../../functions/masks';

function DataTable({ columns = [], data = [], pageChange = null, allPages = 0, allRegister = 0, rowNumber = 0, order = '', desc = '', handleSort = null, actions = null, topAction = null, cleanPage = null, page = 1 }) {
  const [dataItens, setDataItens] = useState(data);

  const sortIcons = (column) => {
    if (column.sortable) {
      if (desc === 'Deferido')
        return <Icon iconName="FaSortDown" size={15} color='light' />
      else if (desc === 'Indeferido')
        return <Icon iconName="FaSortUp" size={15} color='light' />
      else
        return <Icon iconName="FaSort" size={15} color='light' />

    }
    else
      return null;
  }

  const changePage = (actualPage) => {
    if (actualPage > allPages)
      return;
    if (actualPage <= 0)
      return;

    pageChange(actualPage)
    // setPage(actualPage);
  }

  // useEffect(() => {
  //   pageChange(page);
  //   // eslint-disable-next-line
  // }, [page])

  useEffect(() => {
    setDataItens(data)
  }, [data])

  return (
    <Container>
      {topAction && <TopArea>
        <div>
          {topAction}
        </div>
      </TopArea>}
      <Table>
        <thead>
          <TableRow>
            {columns.map((column, i) => (
              <TableHead key={column.name + i} largura={column.width}>
                <ActionArea onClick={() => column.sortable && handleSort(column.property, desc === 'Deferido' ? 'Indeferido' : desc === 'Indeferido' ? 'Aguardando' : 'Deferido')} header>
                  {column.name}<div>{sortIcons(column)}</div>
                </ActionArea>
              </TableHead>
            ))}
            {actions ? <TableHead>Ação</TableHead> : null}
          </TableRow>
        </thead>

        <tbody>
          {dataItens.length > 0 ? dataItens.map((item, index) => (
            <TableRowBody key={`tr${index}${item.nome}`}>
              {columns.map(column => {
                if (column.property === 'deferido') {
                  switch (item.deferido) {
                    case undefined:
                      return <Td key={`td${index}${item.deferido}`}>
                        <ColorTd />
                      </Td>
                    case true:
                      return <Td key={`td${index}${item.deferido}`}>
                        <ColorTd colorBg='success' />
                      </Td>
                    case false:
                      return <Td key={`td${index}${item.deferido}`}>
                        <ColorTd colorBg='danger' />
                      </Td>
                  }
                }
                if (column.property === 'tipo') {
                  switch (item.tipo) {
                    case 'PessoaFisica':
                      return <Td key={column.property} icon >
                        <Icon
                          iconName='BsFillPersonFill'
                          color='primary'
                          bs
                        />
                      </Td>
                    case 'PessoaJuridica':
                      return <Td key={column.property} icon>
                        <Icon
                          iconName='BsBuilding'
                          color='primary'
                          bs
                        />
                      </Td>
                  }
                }
                if (column.property === 'cpfCnpj') {
                  return <Td key={column.property}>{item[column.property].length === 11 ? cpfMask(item[column.property]) : cnpjMask(item[column.property])}</Td>
                }
                return column.property ? <Td key={column.property}>{column.date ? moment(item[[column.property]]).format('DD/MM/yyyy') : column.boolean ? item[column.property] === true ? 'Ativo' : 'Inativo' : item[column.property] ?? '(sem informação)'}</Td> : null
              })}
              {actions ? <Td><ActionArea>{actions.map((action, i) =>
                (action.conditionalStatus === undefined || ((Array.isArray(action.conditionalStatus) && action.conditionalStatus.includes(item.status)) || action.conditionalStatus === item.ativo)) &&
                <React.Fragment key={i}>
                  <Button minify rounded color={action.color} click={() => action.click(action.return ? item : action.index ? index : `${action.action}${item.id}`, action.return && action.action ? action.action : null)} tooltip={action.tooltip}>
                    <Icon iconName={action.iconName} color={action.iconColor} />
                  </Button>
                </React.Fragment>
              )}</ActionArea></Td> : null}
            </TableRowBody>
          )) :
            <TableRowBody>
              <Td colSpan={actions ? columns.length + 1 : columns.length}>Nenhum registro</Td>
            </TableRowBody>}
        </tbody>

        <tfoot>
          <tr>
            <td colSpan={actions ? columns.length + 1 : columns.length}>
              <PaginationArea>
                <CounterArea>
                  Mostrando {dataItens.length > 0 ? page === 1 ? 1 : (page - 1) * rowNumber + 1 : 0} até  {((page - 1) * rowNumber + rowNumber) > allRegister ? allRegister : (page - 1) * rowNumber + rowNumber} de {allRegister}
                </CounterArea>
                <PagesArea>
                  <ButtonPagination onClick={() => changePage(1)}>
                    <Icon iconName="FaAngleDoubleLeft" color='light' />
                  </ButtonPagination>
                  <ButtonPagination onClick={() => changePage(page - 1)}>
                    <Icon iconName="FaAngleLeft" color='light' />
                  </ButtonPagination>
                  <ButtonPagination onClick={() => changePage(page + 1)}>
                    <Icon iconName="FaAngleRight" color='light' />
                  </ButtonPagination>
                  <ButtonPagination onClick={() => changePage(allPages)}>
                    <Icon iconName="FaAngleDoubleRight" color='light' />
                  </ButtonPagination>
                </PagesArea>
              </PaginationArea>
            </td>
          </tr>
        </tfoot>
      </Table>
    </Container>
  );
}

export default DataTable;