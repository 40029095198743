import React, { useCallback, useEffect, useRef, useState } from 'react';
import Button from '../../components/Button';
import DataTable from '../../components/DataTable';
import Input from '../../components/Input';
import Modal from '../../components/Modal';
import TemplatePage from '../../components/TemplatePage';
import { Container, Content, FormStyled, InputContainer, Title } from './styles';
import CnaeStore from '../../stores/CnaeStore';
import { toast } from 'react-toastify';
import Radio from '../../components/Radio';

function Cnaes() {
  const formRef = useRef(null);
  const [cnaes, setCnaes] = useState([]);
  const [filtros] = useState({});
  const [totalPaginas, setTotalPaginas] = useState(0);
  const [totalRegistro, setTotalRegistro] = useState(0);
  const [param, setParam] = useState({ pagina: 1, registroPorPagina: 15, ordernarPor: 'desc' });
  const [crud, setCrud] = useState({ title: 'Criar CNAE', disabled: false, buttonText: 'Criar', create: true, id: 0 });
  const [modalOptions, setModalOptions] = useState({ open: false });

  const colunas = [
    { name: '', property: 'id', sortable: false, width: '15%' },
    { name: 'Descrição', property: 'descricao', width: '70%', sortable: false },
  ];

  const getCnae = useCallback(async (id) => {
    await CnaeStore.getById(id).then(response => {
      if (response.status === 200) {
        formRef.current.setFieldValue('id', response.data.id)
        formRef.current.setFieldValue('descricao', response.data.descricao)
        formRef.current.setFieldValue('obrigatoriCADASTUR', response.data.obrigatoriCADASTUR)
      }
      else {
        toast.error(response.data.mensagem);
      }
    })
  }, [])

  const viewCnae = useCallback(async (cnae) => {
    await getCnae(cnae.id);
    await setCrud({ title: `Visualizar CNAE ${cnae.id}`, disabled: true, buttonText: '', create: false, id: cnae.id })
  }, [getCnae]);

  const editCnae = useCallback(async (cnae) => {
    await getCnae(cnae.id);
    await setCrud({ title: `Editar CNAE ${cnae.id}`, disabled: false, buttonText: 'Editar', create: false, id: cnae.id })
  }, [getCnae]);

  const openModal = (cnae) => {
    setModalOptions({ open: true, cnae: cnae })
  }

  const acao = [
    { text: '', iconName: 'FaRegEye', color: 'background', click: viewCnae, return: true, tooltip: 'Visualizar' },
    { text: '', iconName: 'FaEdit', color: 'warning', iconColor: 'dark', click: editCnae, return: true, tooltip: 'Editar' },
    { text: '', iconName: 'FaTimes', color: 'danger', iconColor: 'light', click: openModal, return: true, conditionalStatus: true, tooltip: 'Inativar' },
    { text: '', iconName: 'FaCheck', color: 'success', iconColor: 'light', click: openModal, return: true, conditionalStatus: false, tooltip: 'Ativar' },
  ];

  function handlePageChange(params) {
    if (param.pagina === params)
      return;
    setParam({ ...param, pagina: params });
  }

  const cancelButton = useCallback(() => {
    formRef.current.reset();
    setCrud({ title: 'Criar CNAE', disabled: false, buttonText: 'Criar', create: true, id: 0 });
  }, [formRef])

  useEffect(() => {
    const call = async () => {
      await CnaeStore.get({ ...param, ...filtros }).then(response => {
        if (response.status === 200) {
          setCnaes(response.data.lista);
          setTotalPaginas(response.data.totalDePaginas);
          setTotalRegistro(response.data.totalDeRegistros);
        }
        else {
          toast.error(response.data.mensagem);
        }
      })
    }

    call();
  }, [filtros, param])

  const submit = useCallback(async (data) => {
    data.obrigatoriCADASTUR = data.obrigatoriCADASTUR === 'true'
    if (crud.create) {
      await CnaeStore.create(data).then(response => {
        if (response.status === 201) {
          cancelButton();
          toast.success(response.data.mensagem);
          setParam({ ...param, pagina: 1 });
        }
        else
          toast.error(response.data.mensagem);
      })
    }
    else {
      await CnaeStore.put(crud.id, data).then(response => {
        if (response.status === 200) {
          cancelButton();
          toast.success(response.data.mensagem);
          setParam({ ...param, pagina: 1 });
        }
        else
          toast.error(response.data.mensagem);
      })
    }

  }, [crud, cancelButton, param])

  const ativarOuInativar = useCallback(async (cnae) => {
    CnaeStore.ActiveOrInactive(cnae.id, !cnae.ativo).then(response => {
      if (response.status === 200) {
        toast.success(response.data.mensagem);
        setParam({ ...param, pagina: 1 })
      }
      else
        toast.error(response.data.message);

      setModalOptions({ open: false });
    })
  }, [param])

  const buttonsModalInativar = [
    <Button text="cancelar" click={() => setModalOptions({ open: false })} color="background"></Button>,
    <Button text="Inativar" click={() => ativarOuInativar(modalOptions.cnae)} color="danger"></Button>,
  ];

  const buttonsModalAtivar = [
    <Button text="cancelar" click={() => setModalOptions({ open: false })} color="background"></Button>,
    <Button text="Ativar" click={() => ativarOuInativar(modalOptions.cnae)} color="success"></Button>,
  ];

  return (
    <TemplatePage title='CNAEs'>
      <Container>
        <Content>
          <FormStyled ref={formRef} onSubmit={submit}>
            <Title>{crud.title}</Title>
            <InputContainer>
              <Input name='id' disabled={!crud.create} label='Código' />
            </InputContainer>
            <InputContainer>
              <Input name='descricao' disabled={crud.disabled} label='Descrição' />
            </InputContainer>
            <InputContainer>
              <Radio
                name='obrigatoriCADASTUR'
                label='Obrigatório CADASTUR'
                options={[
                  { label: 'Sim', value: true },
                  { label: 'Não', value: false },
                ]}
              />
            </InputContainer>
            {!crud.title.includes('Visualizar') && <Button text={crud.buttonText} type='submit' />}
            {!crud.create && <Button text='cancelar' color='background' click={cancelButton} />}
          </FormStyled>
          <DataTable
            columns={colunas}
            actions={acao}
            data={cnaes}
            pageChange={handlePageChange}
            allPages={totalPaginas}
            allRegister={totalRegistro}
            rowNumber={param.registroPorPagina}
            handleSort={(column, desc) => setParam({ ...param, ordernarPor: desc })}
            desc={param.ordernarPor}
            page={param.pagina}
          />
          {modalOptions.open && <Modal title={`${modalOptions.cnae.ativo ? 'Inativar' : 'Ativar'} CNAE`} actions={modalOptions.cnae.ativo ? buttonsModalInativar : buttonsModalAtivar}>
            <span>Deseja {modalOptions.cnae.ativo ? 'inativar' : 'ativar'} este CNAE?</span>
          </Modal>}
        </Content>
      </Container>
    </TemplatePage>
  );
}

export default Cnaes;