import Api from '../functions/api';

const get = (id) => {
  return Api.get(`/Alfresco/${id}`, {
    responseType: 'blob'
  });
}

const methods = {
  get,
}

export default methods;