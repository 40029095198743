export const telephoneMask = v => {
  v = v.replace(/\D/g, "");
  v = v.replace(/^(\d{2})(\d)/g, "($1) $2");
  v = v.replace(/(\d)(\d{4})$/, "$1-$2");
  return v;
}

export const cepMask = v => {
  v = v.replace(/\D/g, "");
  v = v.replace(/(\d)(\d{3})$/, "$1-$2");
  return v;
}

export const cnpjMask = v => {
  v = v.replace(/\D/g, "");
  v = v.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
  return v;
}

export const ieMask = v => {
  v = v.replace(/\D/g, "");
  v = v.replace(/^(\d{2})(\d{3})(\d{3})(\d{1})/, "$1.$2.$3-$4");
  return v;
}

export const cpfMask = value => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1')
}

export const agenciaMask = value => {
  return value
    .replace(/\D/g, '')
    .replace(/^(\d{1,4})(\d{1})/g, '$1-$2')
}

export const contaMask = value => {
  return value
    .replace(/\D/g, '')
    .replace(/^(\d{5,12})(\d{1})/g, '$1-$2')
}