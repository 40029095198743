import React, { useCallback } from 'react';
import { documentacao } from '../../../functions/labelSwitch';
import { cepMask } from '../../../functions/masks';
import { downloadFile } from '../../../functions/utils';
import { FieldLink, FieldText, NameText } from '../styles';
import AlfrescoStore from '../../../stores/AlfrescoStore';
import { toast } from 'react-toastify';

function DadosComplementares({ dados }) {

  const download = useCallback(async (id, tipo) => {
    await AlfrescoStore.get(id).then(response => {
      if (response.status === 200) {
        downloadFile(response.data, tipo);
      }
      else {
        toast.error(response.data.mensagem);
      }
    })
  }, [])

  return (
    <>
      <FieldText>
        Telefone: {dados.telefone}
      </FieldText>
      <FieldText>
        Email: {dados.email}
      </FieldText>
      <FieldText>
        CEP: {cepMask(dados.cep)}
      </FieldText>
      <FieldText>
        Municipio: {dados.cidade}
      </FieldText>
      <FieldText>
        Bairro: {dados.bairro}
      </FieldText>
      <FieldText>
        Logradouro: {dados.logradouro}
      </FieldText>
      <FieldText>
        Nº: {dados.numero}
      </FieldText>
      <FieldText>
        Complemento: {dados.complemento}
      </FieldText>
      <NameText>
        Dados Bancários
      </NameText>
      <FieldText>
        Banco: {dados.bancoNome}
      </FieldText>
      {dados.bancoTipoConta && <FieldText>
        Tipo de conta: {dados.bancoTipoConta}
      </FieldText>}
      {dados.bancoModalidade && <FieldText>
        Modalidade da conta: {dados.bancoModalidade}
      </FieldText>}
      <FieldText>
        Agência: {dados.bancoAgencia}
      </FieldText>
      <FieldText>
        Conta Corrente: {dados.bancoContaCorrente}
      </FieldText>
      <NameText>
        Documentação
      </NameText>
      {dados.documentos.map(doc => (
        <FieldLink onClick={() => download(doc.documentoAlfrescoId, doc.tipoDocumento)}>
          {documentacao(doc.tipoDocumento, dados.tipoPessoa === 'PessoaFisica')}
        </FieldLink>
      ))}
    </>
  );
}

export default DadosComplementares;