import React from 'react';
import { useCallback } from 'react';
import { useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import Icon from '../../components/Icon';
import Input from '../../components/Input';
import { ButtonCard, CardBody, CardForm, CardMenu, CardTitle, Container } from './styles';

function Home() {
  const formRef = useRef(null);
  let history = useHistory();

  const redirectVisualizar = useCallback(() => {
    const protocolo = formRef.current.getFieldValue('protocolo');
    if (protocolo === '') {
      toast.error('Informe um protocolo');
      return;
    }
    history.push(`/VisualizarProtocolo/${protocolo}`);
  },[formRef, history])

  const enter = () => {
    formRef.current.submitForm();
  }

  return (
    <Container>
      <CardMenu to='configuracao'>
        <CardTitle colorBg='PRIMARY'>
          configuração
        </CardTitle>
        <Icon color='primary' iconName='BsGearFill' size={100} bs />
        <ButtonCard colorTxt='PRIMARY'>
          visualizar
        </ButtonCard>
      </CardMenu>
      <CardMenu to='habilitacao'>
        <CardTitle colorBg='TITLE'>
          habilitação
        </CardTitle>
        <Icon color='TITLE' iconName='BsListCheck' size={100} bs />
        <ButtonCard colorTxt='TITLE'>
          visualizar
        </ButtonCard>
      </CardMenu>
      <CardMenu to='classificacao'>
        <CardTitle colorBg='empresa'>
          classificação
        </CardTitle>
        <Icon color='empresa' iconName='FaMedal' size={100} />
        <ButtonCard colorTxt='empresa'>
          visualizar
        </ButtonCard>
      </CardMenu>
      <CardForm ref={formRef} onSubmit={redirectVisualizar}>
        <CardTitle colorBg='guia'>
          Visualizar Inscrição
        </CardTitle>
        <CardBody>
          <Input name='protocolo' label='Protocolo' enter={enter} />
        </CardBody>
        <ButtonCard colorTxt='guia' onClick={() => redirectVisualizar()}>
          visualizar
        </ButtonCard>
      </CardForm>
    </Container>
  );
}

export default Home;