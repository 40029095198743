import React from 'react';
import { TooltipTextComponent } from './styles';

function TooltipText({children}) {
  return (
  <TooltipTextComponent>
    {children}
  </TooltipTextComponent>);
}

export default TooltipText;