import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const CheckboxesContainer = styled.div`
  display: flex;
`

export const CheckboxContainer = styled.span`
  display: flex;
  align-items: center;
  margin: 10px 100px 10px 0;
`

export const RadioLabel = styled.label`
  margin: 0 5px;
`

export const TextError = styled.span`
  color: ${props => props.theme.DANGER};
`