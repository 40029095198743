import React from 'react';
import Icon from '../../../components/Icon';
import { cnpjMask, cpfMask, ieMask } from '../../../functions/masks';
import { DefText, Divider, FieldText, NameText, Status } from '../styles';

function DadosPJ({dados}) {
  return (
    <>
     {dados.deferido !== undefined && (
        <>
         <Status deferido={dados.deferido}>
            <Icon iconName={dados.deferido ? 'FaThumbsUp' : 'FaThumbsDown'} color={dados.deferido ? 'success' : 'danger'} />
            <DefText>{dados.deferido ? 'Deferido' : 'Indeferido'}</DefText>
          </Status>
          {!dados.deferido ? `Motivo: ${dados.motivoIndeferido}` : null}
          <Divider />
        </>
      )}
      <NameText>
        Nome fantasia: {dados.nomeFantasia}
      </NameText>
      <FieldText>
        Razão social: {dados.razaoSocial}
      </FieldText>
      <FieldText>
        CNPJ: {cnpjMask(dados.cnpj)}
      </FieldText>
      <FieldText>
        Inscrição Estadual: {dados.inscricaoEstadual ? ieMask(dados.inscricaoEstadual) : 'Não informado'}
      </FieldText>
      <FieldText>
        Nome Completo do Prorietário ou Responsável: {dados.responsavel}
      </FieldText>
      <FieldText>
        CPF do Responsável: {dados.cpf ? cpfMask(dados.cpf) : ''}
      </FieldText>
      <FieldText>
        Tipo de empresa: {dados.tipo}
      </FieldText>
      <FieldText>
        CNAE: {dados.cnae} - {dados.cnaeDescricao}
      </FieldText>
    </>
  );
}

export default DadosPJ;