import styled from 'styled-components';
import { TooltipTextComponent } from '../TooltipText/styles';

export const ButtonTag = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${props => props.rounded ? '50%' : '5px'};
  background-color: ${props => props.theme[props.colorType]};
  border: none;
  cursor: pointer;
  padding: ${props => props.minify ? '5px 5px' : '10px 15px'};
  margin: ${props => props.noMargin ? '0' : '1px 2px'};
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 700;
  outline: none;
  color: ${props => !props.dark ? props.theme.LIGHT : props.theme.DARK};
  z-index: 4;
  position: relative;

  &:hover {
    background-color: ${props => props.disabled ? 'none' : props.theme[props.colorType + '_DARKER']};
  }

  &:hover ${TooltipTextComponent} {
    visibility: visible;
    opacity: 1;
  }
`;

export const TextArea = styled.span`
  margin: 0 5px;
  white-space: nowrap;
  color: ${props => !props.dark ? props.theme.LIGHT : props.theme.DARK};
`
