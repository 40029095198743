import axios from "axios";
import { createContext, useState, useEffect } from "react";
import Api from '../../functions/api';

const UserContext = createContext(undefined);
const UserDispatchContext = createContext(undefined);

const UserProvider = ({ children }) => {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('@userIMMST')));

  const authenticate = (usuario) => {
    localStorage.setItem('@userIMMST', JSON.stringify(usuario));
    setUser(usuario)
  }

  const logOut = () => {
    localStorage.removeItem('@userIMMST');
    setUser(null);
  }

  const updateSession = (token, tokenExpire) => {
    let usuario = JSON.parse(localStorage.getItem('@userIMMST'));
    usuario.tokenString = token;
    usuario.tokenExpiresIn = tokenExpire;
    localStorage.setItem('@userIMMST', JSON.stringify(usuario));
    setUser(usuario);
  }

  const updateProfile = (user) => {
    let usuario = JSON.parse(localStorage.getItem('@userIMMST'));
    const newUser = {...usuario, ...user};
    localStorage.setItem('@userIMMST', JSON.stringify(newUser));
    setUser(newUser);
  }

  useEffect(() => {
    const authInterceptor = Api.api.interceptors.response.use(response => {
      if (response.status === 401 && user) {
        logOut();
        throw new axios.Cancel('Operation canceled by the user.');
      }
      return response
    }, async (error) => {
      if (error?.response?.status === 401) {
        logOut();
        throw new axios.Cancel('Operation canceled by the user.');
      }
      return error.response;
    });
    return () => {
      Api.api.interceptors.response.eject(authInterceptor);
    }
  }, [user])

  const renewToken = () => {
    Api.get('/Authentication/Renew').then(response => {
      if (response.status === 200) {
        updateSession(response.data.tokenString, response.data.tokenExpiresIn);
      }
    })
  }

  useEffect(() => {
    if (user) {
      var seconds = Math.round(new Date() / 1000);
      var mili = (user.tokenExpiresIn - seconds - 1200) * 1000;
      if (mili > 0) {
        var x = setTimeout(() => renewToken(), mili);
      }
      else if (mili <= 0 && mili > -600000) {
        renewToken();
      }
      else
        logOut();

      return () => clearTimeout(x)
    }

    // eslint-disable-next-line
  }, [user])

  return (
    <UserContext.Provider value={{ user, authenticate, logOut, updateProfile }}>
      {children}
    </UserContext.Provider>
  );
}

export { UserProvider, UserContext, UserDispatchContext };