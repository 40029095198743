import React, { useEffect, useRef } from 'react';
import { ContainerInput, FocusBorder, InputArea, InputField, Label, TextError } from './styles';
import { useField } from '@unform/core';

function TextArea({ name, placeholder, label, setValue, disabled = false }) {
  const inputRef = useRef(null);
  const { fieldName, registerField, defaultValue, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value'
    })
  }, [fieldName, registerField]);

  return (
    <>
      <InputArea>
        {label ?
          <Label>
            {label}
          </Label> :
          null
        }
        <ContainerInput>
          <InputField
            ref={inputRef}
            // placeholder={placeholder}
            defaultValue={defaultValue}
            // onChange={change}
            disabled={disabled}
          />
        </ContainerInput>
      </InputArea>
      {error && <TextError>{error}</TextError>}
    </>
  );
}

export default TextArea;