import React, { useEffect, useRef } from 'react';
import { Container, RadioContainer, RadioLabel, RadiosContainer, TextError } from './styles';
import { useField } from '@unform/core';

function Radio({ label, name, setValue, options = [], disabled = false }) {
  const inputRefs = useRef([]);
  const { fieldName, registerField, defaultValue, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRefs,
      getValue: refs => {
        return refs.current.find(input => input?.checked)?.value
      },
      setValue: (refs, value) => {
        const inputRef = refs.current.map(ref => {
          if ((ref.value === 'true') === value) {
            return ref
          }
        }).filter(Boolean);
        if (inputRef) {
          inputRef[0].checked = true
        }
      },
      clearValue: refs => {
        const inputRef = refs.current.find(ref => ref.checked === true)
        if (inputRef) inputRef.checked = false
      },

    })
  }, [fieldName, registerField]);

  function onChangeValue(event) {
    if (setValue)
      setValue(event.target.value);
  }

  useEffect(() => {
    if (defaultValue !== undefined) {
      let input = inputRefs.current.find(x => x.value === String(defaultValue));
      if (input) {
        input.checked = true;
      }
      if (setValue)
        setValue(defaultValue)
    }
  }, [defaultValue, setValue]);

  return (
    <Container>
      {label}
      <RadiosContainer onChange={onChangeValue}>
        {options.map((option, index) => (
          <RadioContainer key={`radio${name}${index}`}>
            <input
              type="radio"
              ref={ref => {
                inputRefs.current[index] = ref
              }}
              id={`radio${name}${index}`}
              name={name}
              defaultChecked={String(defaultValue) === String(option.value)}
              value={option.value}
              disabled={disabled}
            />
            <RadioLabel htmlFor={`radio${name}${index}`} key={`radio${name}${index}`}>
              {option.label}
            </RadioLabel>
          </RadioContainer>
        ))}
      </RadiosContainer>
      {error && <TextError>{error}</TextError>}
    </Container>
  );
}

export default Radio;