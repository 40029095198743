import { useField } from '@unform/core';
import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';
import Button from '../Button';
import Icon from '../Icon';
import { Container, FilesArea, TextError } from './styles';

function Dropzone({ name, textDrop, maxFiles = 1 }) {
  const { fieldName, registerField, error } = useField(name);
  const [myFiles, setMyFiles] = useState([])
  const onDrop = useCallback(acceptedFiles => {
    if(acceptedFiles.length + myFiles.length > maxFiles){
      toast.error(`Máximo de ${maxFiles} arquivo(s) para anexo`);
      return;
    }
    setMyFiles([...myFiles, ...acceptedFiles])
  }, [myFiles,maxFiles])
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    inputRef,
  } = useDropzone({ accept: 'image/*,application/pdf', onDrop, maxFiles: maxFiles, multiple: maxFiles > 1 });

  const removeFile = file => {
    const newFiles = [...myFiles]
    newFiles.splice(newFiles.indexOf(file), 1)
    setMyFiles(newFiles)
  }

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      getValue: () => {
        return myFiles;
      },
    })
  }, [fieldName, registerField, myFiles, inputRef]);

  return (
    <>
      <Container {...getRootProps({ isDragActive, isDragAccept, isDragReject })}>
        <input {...getInputProps()} />
        <p>{textDrop}</p>
      </Container>
      <FilesArea>
        {myFiles && myFiles.map((file, i) => (
          <Button
            minify
            text={file.name}
            tooltip="Clique para remover"
            click={() => removeFile(file)}
            key={`file${file.name}`}
          >
            <Icon iconName='FaFileAlt' color='light' />
          </Button>
        ))}
      </FilesArea>
      {error && <TextError>{error}</TextError>}
    </>
  );
}

export default Dropzone;