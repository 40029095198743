import Api from '../functions/api';
// import { serialize } from '../functions/objectToQuery';

const create = (body) => {
  return Api.post('/InscricaoPessoaFisica', body,
    {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
}

const methods = {
  create,
}

export default methods;