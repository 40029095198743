import React, { useEffect, useRef, useState } from 'react';
import { ContainerInput, ConteinerIcon, InputArea, InputField, Label, TextError } from './styles';
import { useField } from '@unform/core';
import Icon from '../Icon';
import { cepMask, cnpjMask, ieMask, telephoneMask, cpfMask, agenciaMask, contaMask } from '../../functions/masks';

function Input({ name, placeholder, label, setValue, mask = false, typeMask = 'cpf',
  disabled = false, enter = null, type = "text", max = null, maxLength = null, 
  blur = null, min = null }) {
  const inputRef = useRef(null);
  const [view, setView] = useState(false);
  const { fieldName, registerField, defaultValue, error } = useField(name)

  const change = (event) => {
    if (mask) {
      switch (typeMask) {
        case 'cpf':
          event.target.value = cpfMask(event.target.value);
          break;
        case 'telefone':
          event.target.value = telephoneMask(event.target.value);
          break;
        case 'cep':
          event.target.value = cepMask(event.target.value);
          break;
        case 'cnpj':
          event.target.value = cnpjMask(event.target.value);
          break;
        case 'ie':
          event.target.value = ieMask(event.target.value);
          break;
        case 'agencia':
          event.target.value = agenciaMask(event.target.value);
          break;
        case 'conta':
          event.target.value = contaMask(event.target.value);
          break;
        default:
          event.target.value = cpfMask(event.target.value);
          break;
      }
    }
    if (setValue)
      setValue(event.target.value);

    inputRef.current.value = event.target.value;
  };

  const prevent = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (enter) {
        enter();
      }
    }
  }

  useEffect(() => {
    if (defaultValue) {
      if (setValue)
        setValue(mask ? cpfMask(defaultValue) : defaultValue);
      else if (mask) {
        inputRef.current.value = cpfMask(defaultValue)
      }
    }

    // eslint-disable-next-line
  }, [defaultValue]);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value'
    })
  }, [fieldName, registerField]);

  return (
    <>
      <InputArea>
        {label ?
          <Label>
            {label}
          </Label> :
          null
        }
        <ContainerInput>
          <InputField
            ref={inputRef}
            placeholder={placeholder}
            defaultValue={defaultValue}
            onChange={change}
            disabled={disabled}
            onKeyPress={prevent}
            type={type === 'password' ? view ? 'text' : 'password' : type}
            typePassword={type === 'password'}
            max={max}
            maxLength={maxLength}
            onBlur={blur}
            min={min}
          />
          {type === 'password' &&
            <ConteinerIcon onClick={() => { setView(!view) }}>
              {view ? <Icon iconName='FaRegEye' /> : <Icon iconName='FaRegEyeSlash' />}
            </ConteinerIcon>}
        </ContainerInput>
      </InputArea>
      {error && <TextError>{error}</TextError>}
    </>
  );
}

export default Input;