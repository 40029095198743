import React from 'react';
import Card from '../Card';
import { Container, Divider, Header, HeaderArea, HeaderContent, IconArea, Title } from './styles';
import Button from '../Button';
import { useHistory } from 'react-router-dom';
import Icon from '../Icon';

function PageBody({ title, children, footer, icon, voltar = false, bs=false }) {
  let history = useHistory();
  return (
    <Container>
      <Card>
        <Header>
          <HeaderContent>
            {voltar && <Button color="background" text="VOLTAR" click={() => history.goBack()}><Icon iconName="FaLongArrowAltLeft" size={16} color='DARK' /></Button>}
            <Title>
              <HeaderArea>
                {icon && <IconArea><Icon iconName={icon} size={20} bs={bs} color='primary'/></IconArea>}
                {title}
              </HeaderArea>
            </Title>
          </HeaderContent>
          <Divider />
          {children}
          {footer && <>
            <Divider />
            {footer}
          </>}
        </Header>
      </Card>
    </Container>
  );
}

export default PageBody;