import styled from 'styled-components';
import { createGlobalStyle } from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh
`;

export default createGlobalStyle`
  *{
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;

    :focus {
      outline: none;
    }

    :disabled {
      color: inherit;
      opacity: 0.7;
      cursor: default;
    }
  }

  body{
    max-width:100vw;
    height: auto;
    background-color: ${props => props.theme.BACKGROUND};
    display: flex;
    min-height: 100vh;
  }

  #root {
    height: 100%;
    min-height: 100vh;
    width: 100vw;
  }
`
