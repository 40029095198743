import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px 10% 80px 10%;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`

export const Divider = styled.hr`
  width: 95%;
  border: none;
  border-top: solid 1px ${props => props.theme.BACKGROUND};
  margin: 10px 0;
`

export const HeaderContent = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  position: relative;
  height: 40px;
`

export const Title = styled.h3`
  position: absolute;
  width: 100%;
  text-align: center;
`

export const HeaderArea = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${props => props.theme.PRIMARY};
`

export const IconArea = styled.div`
  margin: 0 5px;
`