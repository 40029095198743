import { Form } from '@unform/web';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Content = styled.div`
  width: 80%;
`

export const Title = styled.h2`
  padding: 10px 20px;
  background-color: ${props => props.theme.TITLE};
  color: ${props => props.theme.LIGHT};
  width: fit-content;
  margin: auto;
  border-radius: 5px;
  text-transform: uppercase;
  margin-bottom: 30px;
`

export const CounterArea = styled.div`
  display: flex;
  justify-content: space-evenly;
`

export const CounterCard = styled.div`
  display: flex;
  flex-direction: column;
  color: ${props => props.colorBg?.toUpperCase() === 'LIGHT' ? props.theme.DARK : props.theme.LIGHT};
  background-color: ${props => props.theme[props.colorBg?.toUpperCase()]};
  padding: 10px;
  text-transform: capitalize;
  width: 150px;
`

export const CardCounter = styled.h1`
  color: ${props => props.colorBg?.toUpperCase() === 'DARK' ? props.theme.DARK : props.theme.LIGHT};
  font-size: 60px;
  display: flex;
  padding: 5px;
  margin: 0 5px;
  justify-content: center;
`

export const FormStyled = styled(Form)`
  display: flex;
  width: 100%;
  align-items: center;
  box-sizing: border-box;
  align-items: flex-end;
  margin: 10px 0;
`

export const InputContainer = styled.div`
  margin: 0 5px 0 0;
  width: 100%;
`