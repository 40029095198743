import { useContext, useLayoutEffect } from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import Api from '../functions/api';
import Home from '../pages/Home';
import InscricaoPessoaFisica from '../pages/InscricaoPessoaFisica';
import InscricaoPessoaJuridica from '../pages/InscricaoPessoaJuridica';
import Login from '../pages/Login';
import TipoInscricao from '../pages/TipoInscricao';
import TemplateRoute from './TemplateRoute';
import { UserContext } from '../contexts/user/UserProvider';
import Habilitacao from '../pages/Habilitacao';
import VisualizarInscricao from '../pages/VisualizarInscricao';
import Classificacao from '../pages/Classificacao';
import Configuracao from '../pages/Configuracao';
import Edicoes from '../pages/Edicoes';
import Bancos from '../pages/Bancos';
import Cnaes from '../pages/Cnaes';

function Routes() {
  const { user, logOut } = useContext(UserContext);
  let history = useHistory();

  useLayoutEffect(() => {
    const authInterceptor = Api.api.interceptors.request.use(async (request) => {
      if (process.env.REACT_APP_AMBIENTE !== "local") {
        request.headers['Authorization'] = localStorage.getItem('token');
        if (!request.headers.Authorization || request.headers.Authorization === 'Bearer undefined') {
          request = await Api.getToken(request);
        }
        if (user) {
          request.headers['Custom-Authorization'] = "Bearer " + user.tokenString
        }
        return request;
      }
      else {
        if (user) {
          request.headers['Custom-Authorization'] = "Bearer " + user.tokenString
        }
        return request;
      }
    }, async err => {
      if (err.status === 401) {
        history.push('/');
        return Promise.reject(err.response);
      }
      return Promise.reject(err.response);
    });

    return () => {
      Api.api.interceptors.request.eject(authInterceptor);
    }
  }, [user, history, logOut])


  return (
    <>
      <Switch>
        <>
          <Route
            path="/"
            exact
            children={<TemplateRoute children={<TipoInscricao />} />}
          />
          <Route
            path="/PessoaFisica"
            exact
            children={<TemplateRoute children={<InscricaoPessoaFisica />} />}
          />
          <Route
            path="/PessoaJuridica"
            exact
            children={<TemplateRoute children={<InscricaoPessoaJuridica />} />}
          />
          {!user && (<>
            <Route
              path="/Login"
              exact
              children={<TemplateRoute children={<Login />} />}
            />
            <Route
              path="/Home"
              children={<Redirect to="/login" />}
            />
            <Route
              path="/Habilitacao"
              children={<Redirect to="/login" />}
            />
          </>)}
          {user && (<>
            <Route
              path="/Home"
              children={<TemplateRoute children={<Home />} />}
            />
            <Route
              path="/Habilitacao"
              children={<TemplateRoute children={<Habilitacao />} />}
            />
            <Route
              path="/Classificacao"
              children={<TemplateRoute children={<Classificacao />} />}
            />
            <Route
              path="/Configuracao"
              children={<TemplateRoute children={<Configuracao />} />}
            />
            <Route
              path="/Edicoes"
              children={<TemplateRoute children={<Edicoes />} />}
            />
            <Route
              path="/Bancos"
              children={<TemplateRoute children={<Bancos />} />}
            />
            <Route
              path="/Cnaes"
              children={<TemplateRoute children={<Cnaes />} />}
            />
            <Route
              path="/Visualizar/:id"
              children={<TemplateRoute children={<VisualizarInscricao />} />}
            />
             <Route
              path="/VisualizarProtocolo/:protocolo"
              children={<TemplateRoute children={<VisualizarInscricao />} />}
            />
            <Route
              path="/Login"
              exact
              children={<Redirect to="/home" />}
            />
          </>)}
        </>
      </Switch>
    </>
  );
}

export default Routes;