import { createContext, useState, useEffect, useCallback } from "react";

const FiltersContext = createContext(undefined);
const FiltersDispatchContext = createContext(undefined);

const FiltersProvider = ({ children }) => {
  const [filters, setFilters] = useState([
    {page : 'Habilitacao' , params: { pagina: 1, registroPorPagina: 30, ordenarPor: 'Aguardando' }}
  ]);

  const addFilter = useCallback((filter) => {
    setFilters([...filters, filter]);
  }, [filters])

  const removeFilter = useCallback((page) => {
    setFilters([...filters.filter(filter => filter.page === page)])
  }, [filters])

  const addOrUpdateFilter = useCallback((filter) => {
    var existe = filters.filter(fil => filter.page === fil.page);
    if (existe.length > 0)
      setFilters([...filters.map(fil => {
        if (fil.page !== filter.page)
          return fil
        else
          return { ...filter }
      })])
    else
      addFilter(filter)
  },[filters])
  return (
    <FiltersContext.Provider value={{ filters, removeFilter, addOrUpdateFilter }}>
      {children}
    </FiltersContext.Provider>
  );
}

export { FiltersProvider, FiltersContext, FiltersDispatchContext };