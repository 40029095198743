
export const serialize = (obj) =>{
    let string = [];
    for(let value in obj){
        if(obj.hasOwnProperty(value) && obj[value] !== '' && obj[value] !== {}){
            if(Array.isArray(obj[value])){
                obj[value].map((o,i) => {
                    string.push(encodeURIComponent(value) + `[${i}]`+ "="+ encodeURIComponent(o));
                })
            }
            else
                string.push(encodeURIComponent(value) + "="+ encodeURIComponent(obj[value]));
        }
    }
    return string.join("&");
    
} 