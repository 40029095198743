import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Container = styled.div`
  display: flex;
  height: 100vh;
  justify-content: space-evenly;
  align-items: center;
  background-image: url('/background.jpg') ;
  background-size: cover;
  width: 100%;
`;

export const CardTypeSubscription = styled(Link)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 5px;
  background-color: rgba(0,0,0,0.65);
  width: 300px;
  padding: 0px 0;
  text-decoration: none;
` 
export const Title = styled.h2`
  color: ${props => props.theme[props.colorTxt]};
  margin: 20px 0;
`

export const ButtonCard = styled.h2`
  width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${props => props.theme.LIGHT};
  border-radius: 3px;
  padding: 10px;
  margin: 30px 0;
  background-color: ${props => props.theme[props.colorTxt]};
  box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgba(0,0,0,0);
`