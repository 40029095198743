import React from 'react';
import Icon from '../../components/Icon';
import { ButtonCard, CardTypeSubscription, Container, Title } from './styles';

function TipoInscricao() {
  return (
    <Container>
      <CardTypeSubscription to='/PessoaJuridica'>
        <Title colorTxt='LIGHT'>
          Empresa ME e MEI
        </Title>
        <Icon color='empresa' iconName='BsBuilding' size={130} bs />
        <ButtonCard colorTxt='EMPRESA'>
          Inscrever
        </ButtonCard>
      </CardTypeSubscription>
      <CardTypeSubscription to='/PessoaFisica'>
        <Title colorTxt='LIGHT'>
          Guia de Turismo
        </Title>
        <Icon color='guia' iconName='BsFillPersonFill' size={130} bs />
        <ButtonCard colorTxt='GUIA'>
          Inscrever
        </ButtonCard>
      </CardTypeSubscription>
    </Container>
  );
}

export default TipoInscricao;