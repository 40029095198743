import React, { useEffect, useRef, useState } from 'react';
import { CardBody, CardButton, CardTitle, Container, Content, TextButton, ButtonArea, Title, FormStyled } from './styles';
import DadosPF from './DadosPF';
import DadosPJ from './DadosPJ';
import DadosComplementares from './DadosComplementares';
import InscricaoStore from '../../stores/InscricaoStore';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import TemplatePage from '../../components/TemplatePage';
import Icon from '../../components/Icon';
import Modal from '../../components/Modal';
import Button from '../../components/Button';
import TextArea from '../../components/TextArea';

function VisualizarInscricao() {
  let { id, protocolo } = useParams();
  const [inscricao, setInscricao] = useState(null);
  const [modalOptionsIndef, setModalOptionsIndef] = useState({ open: false });
  const [modalOptionsDef, setModalOptionsDef] = useState({ open: false });
  const formRef = useRef(null);
  let history = useHistory();

  useEffect(() => {
    const call = async () => {
      if (id) {
        await InscricaoStore.get(id).then(response => {
          if (response.status === 200) {
            setInscricao(response.data);
          }
          else {
            toast.error(response.data.mensagem);
            history.goBack();
          }
        })
      }
      if (protocolo) {
        await InscricaoStore.getProtocolo(protocolo).then(response => {
          if (response.status === 200) {
            setInscricao(response.data);
          }
          else {
            toast.error(response.data.mensagem);
            history.goBack();
          }
        })
      }
    }

    call();
  }, [id, protocolo, history])

  const deferir = async () => {
    await InscricaoStore.deferir(id).then(response => {
      if (response.status === 200) {
        toast.success(response.data);
        setModalOptionsDef({ open: false })
        history.push('/habilitacao');
      }
      else {
        toast.error(response.data.mensagem);
      }
    })
  }

  const indeferir = async () => {
    const motivo = formRef.current.getFieldValue('motivo');
    if (motivo === '') {
      toast.error('Motivo é obrigatório');
      return;
    }
    await InscricaoStore.indeferir(id, motivo).then(response => {
      if (response.status === 200) {
        setModalOptionsIndef({ open: false })
        toast.success(response.data);
        history.push('/habilitacao');
      }
      else {
        toast.error(response.data.mensagem);
      }
    })
  }

  const buttonsModalDef = [
    <Button text="cancelar" click={() => setModalOptionsDef({ open: false })} color="background"></Button>,
    <Button text="Deferir" click={deferir} color="success" ></Button>,
  ]

  const buttonsModalIndef = [
    <Button text="cancelar" click={() => setModalOptionsIndef({ open: false })} color="background"></Button>,
    <Button text="Indeferir" click={indeferir} color="danger" ></Button>,
  ]

  return (
    <TemplatePage title='Visualizar inscrição'>
      <Container>
        {inscricao && <Content>
          <CardTitle pj={inscricao?.tipoPessoa === 'PessoaJuridica'} >
            {inscricao.tipoPessoa === 'PessoaJuridica' ?
              <>
                <Icon iconName='BsBuilding' bs size={20} color='light' />
                <Title>Formulário de inscrição de Empresa</Title>
              </> :
              <>
                <Icon iconName='BsFillPersonFill' bs size={20} color='dark' />
                <Title>Formulário de inscrição de Guia de Turismo</Title>
              </>
            }
          </CardTitle>
          <CardBody>
            <> {inscricao.tipoPessoa === 'PessoaJuridica' ?
              <DadosPJ dados={inscricao} /> :
              <DadosPF dados={inscricao} />}
              <DadosComplementares dados={inscricao} />
            </>
          </CardBody>
        </Content>}
      </Container>
      {(protocolo === undefined && inscricao && inscricao.deferido === undefined) && <ButtonArea>
        <CardButton colorBg='danger' onClick={() => setModalOptionsIndef({ open: true })}>
          <TextButton>
            indeferir inscrição
          </TextButton>
          <Icon iconName='FaTimes' color='light' size={20} />
        </CardButton>
        <CardButton colorBg='success' onClick={() => setModalOptionsDef({ open: true })}>
          <TextButton>
            deferir inscrição
          </TextButton>
          <Icon iconName='FaCheck' color='light' size={20} />
        </CardButton>
      </ButtonArea>}
      <FormStyled ref={formRef}>
        {modalOptionsIndef.open &&
          <Modal title="Indeferir inscrição" actions={buttonsModalIndef}>
            <TextArea name='motivo' label='Motivo do indeferimento da inscrição' />
          </Modal>}
        {modalOptionsDef.open &&
          <Modal title="Deferir inscrição" actions={buttonsModalDef}>
            Deseja deferir a inscrição?
          </Modal>}
      </FormStyled>
    </TemplatePage>

  );
}

export default VisualizarInscricao;