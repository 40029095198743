import { Form } from '@unform/web';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Content = styled.div`
  display: grid;
  grid-template-columns: 40% 60%;
  width: 80%;
`

export const FormStyled = styled(Form)`
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: white;
  border-radius: 5px;
  margin-right: 10px;
  height: fit-content;
`

export const InputContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin: 10px 0;
`

export const Title = styled.h2`
  display: flex;
  justify-content: center;
`