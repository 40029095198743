import Api from '../functions/api';
import { serialize } from '../functions/objectToQuery';

const createRank = (qtd) => {
  return Api.put('/Edicao/classificar',{quantidadeDeInscricoes: qtd});
}

const get = (params) => {
  return Api.get(`Edicao/classificados?${serialize(params)}`);
}

const getEdicao = (params) => {
  return Api.get(`Edicao?${serialize(params)}`);
}

const getById = (id) => {
  return Api.get(`Edicao/${id}`);
}

const create = (body) => {
  return Api.post(`Edicao`, body);
}

const put = (id, body) => {
  return Api.put(`Edicao/${id}`, body);
}

const ActiveOrInactive = (id, active) => {
  return Api.put(`Edicao/${active ? 'ativar' : 'desativar'}/${id}`)
}

const finish = () => {
  return Api.put('Edicao/finalizar');
}

const sendEmail = (id) => {
  return Api.put(`Edicao/enviaremail/${id}`,null);
}

const methods = {
  createRank,
  get,
  finish,
  getEdicao,
  create,
  getById,
  put,
  ActiveOrInactive,
  sendEmail
}

export default methods;