import { Link } from "react-router-dom";
import styled from "styled-components";

export const ContainerSidebar = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  flex: 1;
`;

export const Container = styled.div`
  display: flex;
  flex: 1;
`;

export const Header = styled.div`
  position: absolute;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 10%;
  box-sizing: border-box;
  background-color: ${props => props.theme.TITLE};
  width: 100%;
`

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
`

export const LinkTitle = styled(Link)`
  display: flex;
  text-decoration: none;
  cursor: pointer;
  align-items: center;
`

export const Title = styled.h4`
  font-weight: normal;
  color: ${props => props.theme.LIGHT};

`

export const Logo = styled.h3`
  font-size:large;
  color: ${props => props.theme.SECONDARY};
`

export const Footer = styled.div`
  display: flex;
  position: absolute;
  bottom: 0;
  justify-content: center;
  align-items: center;
  left: 50%;
  transform: translatex(-50%);
  background-color: rgba(255,255,255,0.6);
  width: fit-content;
  padding: 0 20px;
  border-radius: 5px 5px 0 0;
`

export const ContainerTemplate = styled.div`
  position: relative;
  width: 100%;
`

export const BodyMargin = styled.div`
  display: flex;
  width: 100%;
`

export const Img = styled.img`
  height: ${props => props.smaller ? '30px' : '50px'};
  margin: ${props => props.noMargin ? '0' : '10px 5px'};
`


export const UserArea = styled.div`
  display: flex;
  align-items: center;
`

export const UserName = styled.h5`
  margin-right: 5px;
  color: ${props => props.theme.LIGHT};
`


export const UserContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  box-sizing: border-box;
` 