import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import DataTable from '../../components/DataTable';
import { CardCounter, Container, Content, CounterArea, CounterCard, FormStyled, InputContainer } from './styles';
import InscricaoStore from '../../stores/InscricaoStore';
import CnaeStore from '../../stores/CnaeStore';
import { toast } from 'react-toastify';
import TemplatePage from '../../components/TemplatePage';
import SelectSearch from '../../components/SelectSearch';
import Button from '../../components/Button';
import { FiltersContext } from '../../contexts/filters/FiltersProvider';

function Habilitacao() {
  const formRef = useRef(null);
  const [inscricoes, setInscricoes] = useState([]);
  const [totalPaginas, setTotalPaginas] = useState(0);
  const [totalRegistro, setTotalRegistro] = useState(0);
  const [aguardando, setAguardando] = useState(0);
  const [deferido, setDeferido] = useState(0);
  const [indeferido, setIndeferido] = useState(0);
  const [cnaes, setCnaes] = useState([]);
  const { addOrUpdateFilter, filters } = useContext(FiltersContext);
  const [param, setParam] = useState(filters.filter(f => f.page === 'Habilitacao')[0].params);
  const [tipo, setTipo] = useState(null);
  let history = useHistory();

  const redirect = (item, link) => {
    history.push(item ? `${link}${item.id}` : link);
  }

  useEffect(() => {
    formRef.current.setFieldValue('tipoPessoa', param.tipoPessoa);
  }, [])

  useEffect(() => {
    if (tipo === 'pessoaJuridica') {
      formRef.current.setFieldValue('cnae', param.cnae);
    }
  }, [cnaes])

  useEffect(() => {
    const call = async () => {
      addOrUpdateFilter({ page: 'Habilitacao', params: { ...param } });
      await InscricaoStore.getPaginated({ ...param }).then(response => {
        if (response.status === 200) {
          setInscricoes(response.data.lista);
          setTotalPaginas(response.data.totalDePaginas);
          setTotalRegistro(response.data.totalDeRegistros);
          setAguardando(response.data.totalDeAguardando);
          setDeferido(response.data.totalDeDeferidos);
          setIndeferido(response.data.totalDeIndeferidos);
        }
        else {
          toast.error(response.data.mensagem);
        }
      })
    }

    call();
  }, [param])

  useEffect(() => {
    const call = async () => {
      await CnaeStore.getAll().then(response => {
        if (response.status === 200) {
          setCnaes([...response.data.map(cnae => {
            return { label: `${cnae.id}`, value: cnae.id }
          })]);
        }
        else {
          toast.error(response.data.mensagem);
        }
      })
    }

    call();
  }, [])

  function handlePageChange(params) {
    if (param.pagina === params)
      return;
    setParam({ ...param, pagina: params });
  }

  const submit = useCallback((data) => {
    setParam({ ...param, cnae: data.cnae, tipoPessoa: data.tipoPessoa, pagina: 1 });
  }, [param])

  const colunas = [
    { name: '', property: 'deferido', sortable: true, width: '1%' },
    { name: '', property: 'tipo', width: '4%', sortable: false },
    { name: 'Nome / Nome Fantasia', property: 'nome', sortable: false, width: '80%', filtered: true },
  ];

  const acao = [
    { text: '', iconName: 'FaRegEye', color: 'background', click: redirect, action: '/visualizar/', return: true, tooltip: 'Visualizar' },
  ];

  const clearInput = useCallback((value) => {
    setTipo(value);
    formRef.current.setFieldValue('cnae', []);
  }, [formRef])

  return (
    <TemplatePage title='habilitação'>
      <Container>
        <Content>
          <CounterArea>
            <CounterCard colorBg='success'>
              deferidas
              <CardCounter colorBg='light'>
                {deferido}
              </CardCounter>
            </CounterCard>
            <CounterCard colorBg='danger'>
              indeferidas
              <CardCounter colorBg='light'>
                {indeferido}
              </CardCounter>
            </CounterCard>
            <CounterCard colorBg='light'>
              aguardando
              <CardCounter colorBg='dark'>
                {aguardando}
              </CardCounter>
            </CounterCard>
          </CounterArea>
          <FormStyled ref={formRef} onSubmit={submit}>
            <InputContainer>
              <SelectSearch name='tipoPessoa' label='Filtro por tipo de inscrição' setValue={clearInput} isClearable dataSelect={[{ label: 'Pessoa Fisica', value: 'pessoaFisica' }, { label: 'Pessoa Juridica', value: 'pessoaJuridica' }]} />
            </InputContainer>
            <InputContainer>
              {(cnaes && cnaes.length > 0) && <SelectSearch name='cnae' label='Filtro por Cnae' dataSelect={cnaes} multi disabled={tipo !== 'pessoaJuridica'} />}
            </InputContainer>
            <Button text='Filtrar' noMargin type='submit' />
          </FormStyled>
          <DataTable
            columns={colunas}
            actions={acao}
            data={inscricoes}
            pageChange={handlePageChange}
            allPages={totalPaginas}
            allRegister={totalRegistro}
            rowNumber={param.registroPorPagina}
            handleSort={(column, desc) => setParam({ ...param, ordenarPor: desc })}
            desc={param.ordenarPor}
            page={param.pagina}
          />
        </Content>
      </Container>
    </TemplatePage>
  );
}

export default Habilitacao;