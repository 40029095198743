import React, { useEffect, useRef } from 'react';
import { Container, CheckboxContainer, RadioLabel, CheckboxesContainer, TextError } from './styles';
import { useField } from '@unform/core';

function Checkbox({ label, name, setValue, options = [], disabled = false }) {
  const inputRefs = useRef([]);
  const { fieldName, registerField, defaultValue, error } = useField(name);

  const change = (event) => {
    if (setValue){
      setValue(inputRefs.current.some(a => a.checked));
    }
  };

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRefs,
      getValue: refs => {
        return refs.current.map(a => a.checked ? {nome:a.value} : null).filter(Boolean);
      },
      setValue: (refs, id) => {
        const inputRef = refs.current.find(ref => ref.id === id)
        if (inputRef) {
          inputRef.checked = true
        }
      },
      clearValue: refs => {
        const inputRef = refs.current.find(ref => ref.checked === true)
        if (inputRef) inputRef.checked = false
      },

    })
  }, [fieldName, registerField]);

  useEffect(() => {
    if (defaultValue !== undefined) {
      inputRefs.current.forEach(i => {
        if(defaultValue.map(d => d.nome).includes(i.value))
          i.checked = true;
        else
          i.checked = false;
      })
      if (setValue)
        setValue(defaultValue)
    }
  }, [defaultValue, setValue]);

  return (
    <Container>
      {label}
      {error && <TextError>{error}</TextError>}
      <CheckboxesContainer>
        {options.map((option, index) => (
          <CheckboxContainer key={`checkbox${name}${index}`}>
            <input
              type="checkbox"
              ref={ref => {
                inputRefs.current[index] = ref
              }}
              id={`checkbox${name}${index}`}
              value={option.label}
              name={name}
              disabled={disabled}
              onChange={change}
            />
            <RadioLabel htmlFor={`checkbox${name}${index}`} key={`checkbox${name}${index}`}>
              {option.label}
            </RadioLabel>
          </CheckboxContainer>
        ))}
      </CheckboxesContainer>
    </Container>
  );
}

export default Checkbox;