import React from 'react';
import Icon from '../../../components/Icon';
import { cpfMask } from '../../../functions/masks';
import { FieldText, NameText, Status, Divider, DefText } from '../styles';

function DadosPF({ dados }) {
  return (
    <>
      {dados.deferido !== undefined && (
        <>
          <Status deferido={dados.deferido}>
            <Icon iconName={dados.deferido ? 'FaThumbsUp' : 'FaThumbsDown'} color={dados.deferido ? 'success' : 'danger'} />
            <DefText>{dados.deferido ? 'Deferido' : 'Indeferido'}</DefText>
          </Status>
          {!dados.deferido ? `Motivo: ${dados.motivoIndeferido}` : null}
          <Divider />
        </>
      )}
      <NameText>
        Nome: {dados.nome}
      </NameText>
      <FieldText>
        CPF: {cpfMask(dados.cpf)}
      </FieldText>
      <FieldText>
        RG: {dados.registroGeral}
      </FieldText>
    </>
  );
}

export default DadosPF;