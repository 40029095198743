import styled from 'styled-components';

export const InputArea = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
`

export const Label = styled.span`
  color: ${props => props.theme.PRIMARY};
`


export const InputField = styled.input`
  font: 15px/24px "Lato", arial, sans-serif;
  color: ${props => props.theme.DARK};
  width: 100%;
  box-sizing: border-box;
  letter-spacing: 1px;
  border: 1px solid ${props => props.theme.PRIMARY};
  border-radius: 5px;
  ${props => props.typePassword ? 'border-right: none;' : null}
  ${props => props.typePassword ? 'border-top-right-radius: 0;' : null}
  ${props => props.typePassword ? 'border-bottom-right-radius: 0;' : null}
  padding: 5px 20px 4px 10px;
  background-color: ${props => props.theme.LIGHT_DARKER};

  &:focus {
    outline: none;
    outline-offset: 0px;
    border: 1px solid ${props => props.theme.PRIMARY_LIGHTER};
  }

  &:disabled {
    opacity: 0.7;
  }
  &&[type='number'] {
    -moz-appearance:textfield;
  }

  &&::-webkit-outer-spin-button,
  &&::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
`

export const TextError = styled.span`
  color: ${props => props.theme.DANGER};
`

export const ContainerInput = styled.div`
  display: flex;
  width: 100%;
`

export const ConteinerIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  color: ${props => props.theme.PRIMARY};
  border: 1px solid;
  background-color: ${props => props.theme.LIGHT_DARKER};
  border-radius: 0 5px 5px 0;
`