import React, { useEffect, useRef, useState } from 'react';
import Icon from '../../components/Icon';
import TemplatePage from '../../components/TemplatePage';
import { ButtonCard, CardBody, CardForm, CardMenu, CardTitle, Container } from './styles';
import EdicaoStore from '../../stores/EdicaoStore';
import { toast } from 'react-toastify';
import SelectSearch from '../../components/SelectSearch';

function Configuracao() {
  const [edicoes, setEdicoes] = useState([]);
  const formRef = useRef(null);

  useEffect(() => {
    const call = async () => {
      await EdicaoStore.getEdicao({ registroPorPagina: 9999 }).then(response => {
        if (response.status === 200) {
          setEdicoes(response.data.lista.map(x => ({ label: x.id, value: x.id })));
        }
        else {
          toast.error(response.data.mensagem);
        }
      })
    }

    call();
  }, [])

  const dispararEmail = async () => {
    const id = formRef.current.getFieldValue('edicao');
    if(id === ''){
      toast.error('Selecione uma edição');
      return
    }
    await EdicaoStore.sendEmail(id).then(response => {
      if (response.status === 200) {
        toast.success(response.data.mensagem);
      }
      else
        toast.error(response.data.mensagem);
    })
  }

  return (
    <TemplatePage title='Configuração'>
      <Container>
        <CardMenu to='edicoes'>
          <CardTitle colorBg='PRIMARY'>
            Edições
          </CardTitle>
          <Icon color='primary' iconName='BsListOl' size={100} bs />
          <ButtonCard colorTxt='PRIMARY'>
            visualizar
          </ButtonCard>
        </CardMenu>
        <CardMenu to='bancos'>
          <CardTitle colorBg='TITLE'>
            Bancos
          </CardTitle>
          <Icon color='TITLE' iconName='FaLandmark' size={100} />
          <ButtonCard colorTxt='TITLE'>
            visualizar
          </ButtonCard>
        </CardMenu>
        <CardMenu to='cnaes'>
          <CardTitle colorBg='empresa'>
            Cnaes
          </CardTitle>
          <Icon color='empresa' iconName='FaIndustry' size={100} />
          <ButtonCard colorTxt='empresa'>
            visualizar
          </ButtonCard>
        </CardMenu>
        <CardForm ref={formRef}>
          <CardTitle colorBg='guia'>
            Disparar E-mails
          </CardTitle>
          <CardBody>
            <SelectSearch name='edicao' label='Edição' dataSelect={edicoes} isClearable/>
          </CardBody>
          <ButtonCard colorTxt='guia' onClick={dispararEmail} >
            disparar
          </ButtonCard>
        </CardForm>
      </Container>
    </TemplatePage>
  );
}

export default Configuracao;