import React from 'react';
import { withTheme } from 'styled-components'
import * as FontAwesome from 'react-icons/fa';
import * as Bootstrap from 'react-icons/bs';

function Icon({ iconName, size, theme, color = 'primary', disabled = false, bs = false }) {
  let IconTag;
  if (bs)
    IconTag = Bootstrap[iconName];
  else
    IconTag = FontAwesome[iconName];

  return <IconTag style={{ width: size, height: size, zIndex: 2, opacity: disabled ? 0.7 : 1, verticalAlign: 'middle' }} color={theme[color.toUpperCase()]} />
}

export default withTheme(Icon);