import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  position: fixed;
  z-index: 5;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0,0,0,0.4);
`;

export const Content = styled.div`
  background-color: white;
  margin: auto;
  padding: 20px;
  max-width: 70%;
  max-height: 80%;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0;
  box-sizing: border-box;
`

export const Divider = styled.hr`
  width: 95%;
  border: none;
  border-top: solid 1px ${props => props.theme.BACKGROUND};
  margin: 10px 0;
`

export const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
`

export const BodyModal = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 20px 30px;
  text-align: justify;
`