import React, { useCallback, useEffect, useRef, useState } from 'react';
import PageBody from '../../components/PageBody';
import Input from '../../components/Input';
import Modal from '../../components/Modal';
import { AgencyContainer, AttachmentArea, ButtonContainer, Container, DigitContainer, Divider, FieldContainer, FormStyled, GroupField, Li, TextObs, TitleArea, Ul } from './styles';
import Dropzone from '../../components/Dropzone';
import Button from '../../components/Button';
import Checkbox from '../../components/Checkbox';
import Radio from '../../components/Radio';
import PessoaJuridicaStore from '../../stores/PessoaJuridicaStore';
import BancoStore from '../../stores/BancoStore';
import LocalidadeStore from '../../stores/LocalidadeStore';
import CnaeStore from '../../stores/CnaeStore';
import { toast } from 'react-toastify';
import jsonFormData from '@ajoelp/json-to-formdata';
import { useHistory } from 'react-router-dom';
import SelectSearch from '../../components/SelectSearch';
import { validarCNPJ, validarCPF } from '../../functions/validate';
import PDFProtocol from '../../components/PDFProtocol';
import { pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';

function InscricaoPessoaJuridica() {
  const formRef = useRef(null);
  const [modalOptions, setModalOptions] = useState({ open: false });
  const [ciente, setCiente] = useState(false);
  const [formData, setFormData] = useState(null);
  const [bancos, setBancos] = useState([]);
  let history = useHistory();
  const [cnaes, setCnaes] = useState([]);

  useEffect(() => {
    const call = async () => {
      await BancoStore.getAll().then(response => {
        if (response.status === 200) {
          setBancos([...response.data.map(bank => {
            return { label: `${bank.codigo} - ${bank.nome}`, value: bank.codigo }
          })]);
        }
        else {
          toast.error(response.data.mensagem);
        }
      })
    }

    call();
  }, [])

  useEffect(() => {
    const call = async () => {
      await CnaeStore.getAll().then(response => {
        if (response.status === 200) {
          setCnaes([...response.data.map(cnae => {
            return { label: `${cnae.id} - ${cnae.descricao}`, value: cnae.id }
          })]);
        }
        else {
          toast.error(response.data.mensagem);
        }
      })
    }

    call();
  }, [])

  const getLocate = useCallback(() => {
    const cep = formRef.current.getFieldValue('cep');
    if (cep.length === 9)
      LocalidadeStore.getLocate(cep).then(response => {
        formRef.current.setFieldError('cep', '')
        if (response.status === 200) {
          formRef.current.setFieldValue('logradouro', response.data.logradouro);
          formRef.current.setFieldValue('cidade', response.data.cidade);
          formRef.current.setFieldValue('bairro', response.data.bairro);
        }
        else
          toast.error(response.data.mensagem);
      })
    else {
      toast.error('CEP inválido');
      formRef.current.setFieldError('cep', 'Campo inválido')
    }
  }, [])

  const validateCNPJ = useCallback(() => {
    const cnpj = formRef.current.getFieldValue('cnpj');
    if (!validarCNPJ(cnpj)) {
      toast.error('CNPJ inválido');
      formRef.current.setFieldValue('cnpj', '');
    }
  }, [])

  const validateCPF = useCallback(() => {
    const cpf = formRef.current.getFieldValue('cpf');
    if (!validarCPF(cpf)) {
      toast.error('CPF inválido');
      formRef.current.setFieldValue('cpf', '');
    }
  }, [])

  const validate = useCallback(async (data) => {
    formRef.current?.setErrors({});
    let error = false;

    if (data.razaoSocial === '') {
      formRef.current?.setFieldError("razaoSocial", "Campo obrigatório")
      error = true;
    }
    if (data.nomeFantasia === '') {
      formRef.current?.setFieldError("nomeFantasia", "Campo obrigatório")
      error = true;
    }
    if (data.cnae === '') {
      formRef.current?.setFieldError("cnae", "Campo obrigatório")
      error = true;
    }
    if (data.responsavel === '') {
      formRef.current?.setFieldError("responsavel", "Campo obrigatório")
      error = true;
    }
    if (data.cpf === '') {
      formRef.current?.setFieldError("cpf", "Campo obrigatório")
      error = true;
    }
    if (data.telefone === '') {
      formRef.current?.setFieldError("telefone", "Campo obrigatório")
      error = true;
    }
    else if (data.telefone.length < 14) {
      formRef.current?.setFieldError("telefone", "Campo inválido")
      error = true;
    }
    if (data.email === '') {
      formRef.current?.setFieldError("email", "Campo obrigatório")
      error = true;
    }
    if (data.bancoCodigo === '') {
      formRef.current?.setFieldError("bancoCodigo", "Campo obrigatório")
      error = true;
    }
    if (data.bancoTipoConta === '') {
      formRef.current?.setFieldError("bancoTipoConta", "Campo obrigatório")
      error = true;
    }
    if (data.bancoModalidade === '') {
      formRef.current?.setFieldError("bancoModalidade", "Campo obrigatório")
      error = true;
    }
    if (data.bancoAgencia === '') {
      formRef.current?.setFieldError("bancoAgencia", "Campo obrigatório")
      error = true;
    }
    else if(data.bancoAgencia.length < 6) {
      formRef.current?.setFieldError("bancoAgencia", "Agência inválida")
      error = true;
    }
    if (data.bancoContaCorrente === '') {
      formRef.current?.setFieldError("bancoContaCorrente", "Campo obrigatório")
      error = true;
    }
    else if(data.bancoContaCorrente.length < 6) {
      formRef.current?.setFieldError("bancoContaCorrente", "Conta inválida")
      error = true;
    }
    if (data.cep === '') {
      formRef.current?.setFieldError("cep", "Campo obrigatório")
      error = true;
    }
    if (data.logradouro === '') {
      formRef.current?.setFieldError("logradouro", "Campo obrigatório")
      error = true;
    }
    if (data.cidade === '') {
      formRef.current?.setFieldError("cidade", "Campo obrigatório")
      error = true;
    }
    if (data.numero === '') {
      formRef.current?.setFieldError("numero", "Campo obrigatório")
      error = true;
    }
    if (data.bairro === '') {
      formRef.current?.setFieldError("bairro", "Campo obrigatório")
      error = true;
    }
    if (data.cnpj === '') {
      formRef.current?.setFieldError("cnpj", "Campo obrigatório")
      error = true;
    }
    
    if (data.tipo === '' || data.tipo === undefined) {
      formRef.current?.setFieldError("tipo", "Campo obrigatório")
      error = true;
    }
    if (data.comprovanteResidencia.length === 0) {
      formRef.current?.setFieldError("comprovanteResidencia", "Campo obrigatório")
      error = true;
    }
    else {
      data.comprovanteResidencia = data.comprovanteResidencia[0];
    }
    if (data.certificadoCadastur.length !== 0)
      data.certificadoCadastur = data.certificadoCadastur[0];

    if (data.copiaCNPJ.length === 0) {
      formRef.current?.setFieldError("copiaCNPJ", "Campo obrigatório")
      error = true;
    }
    else {
      data.copiaCNPJ = data.copiaCNPJ[0];
    }
    if (data.registroPublico.length !== 0)
      data.registroPublico = data.registroPublico[0];
      
    if (data.comprovanteAtividade.length === 0) {
      formRef.current?.setFieldError("comprovanteAtividade", "Campo obrigatório")
      error = true;
    }
    if (data.copiaCpf.length === 0) {
      formRef.current?.setFieldError("copiaCpf", "Campo obrigatório")
      error = true;
    }
    if (data.copiaCartao.length === 0) {
      formRef.current?.setFieldError("copiaCartao", "Campo obrigatório")
      error = true;
    }
    else {
      data.copiaCartao = data.copiaCartao[0];
    }
    if (error) {
      return;
    }
    setFormData(data);
    setModalOptions({ open: true });
  }, [])

  const subscribe = useCallback(async () => {
    const form = jsonFormData(formData);
    formData.comprovanteAtividade.map((file, index) => {
      form.delete(`comprovanteAtividade[${index}]`);

      form.append(`comprovanteAtividade[${index}].file`, file);
      form.append(`comprovanteAtividade[${index}].a`, 'a');

      return null;
    })
    formData.copiaCpf.map((file, index) => {
      form.delete(`copiaCpf[${index}]`);

      form.append(`copiaCpf[${index}].file`, file);
      form.append(`copiaCpf[${index}].a`, 'a');

      return null;
    })
    await PessoaJuridicaStore.create(form).then(async (response) => {
      if (response.status === 201) {
        toast.success(response.data.mensagem);
        const doc = <PDFProtocol protocolo={response.data.protocolo} />;
        const asPdf = pdf([]);
        asPdf.updateContainer(doc);
        const blob = await asPdf.toBlob();
        saveAs(blob, `${response.data.protocolo}.pdf`);
        history.push('/');
      }
      else {
        toast.error(response.data.mensagem);
      }
    })
  }, [formData, history])

  const buttonsModal = [
    <Button text="cancelar" click={() => { setModalOptions({ open: false }); setCiente(false); }} color="background"></Button>,
    <Button text="inscrever" click={subscribe} color="primary" disabled={!ciente}></Button>,
  ]

  return (
    <Container>
      <PageBody
        title='Inscrição de Empresa'
        icon='BsBuilding'
        bs
        voltar
      >
        <FormStyled ref={formRef} onSubmit={validate}>
          <FieldContainer half>
            <Input name='nomeFantasia' label='Nome Fantasia' />
          </FieldContainer>
          <FieldContainer half>
            <Input name='razaoSocial' label='Razão social' />
          </FieldContainer>
          <FieldContainer>
            <Radio name='tipo' label='Empresa' options={[{ label: 'MEI', value: 'mei' }, { label: 'ME', value: 'me' }]} />
          </FieldContainer>
          <FieldContainer thirdQ>
            <SelectSearch name='cnae' label='CNAE Principal' dataSelect={cnaes} />
          </FieldContainer>
          <FieldContainer>
            <Input name='cnpj' label='CNPJ' mask typeMask='cnpj' maxLength={18} blur={validateCNPJ} />
          </FieldContainer>
          <FieldContainer>
            <Input name='inscricaoEstadual' label='Inscrição Estadual' mask typeMask='ie' maxLength={12} />
          </FieldContainer>
          <FieldContainer half>
            <Input name='email' label='E-mail' type='email' />
          </FieldContainer>
          <FieldContainer thirdQ>
            <Input name='responsavel' label='Nome do propretário/responsável pela empresa' />
          </FieldContainer>
          <FieldContainer>
            <Input name='cpf' label='CPF do responsável' mask blur={validateCPF} />
          </FieldContainer>
          <FieldContainer>
            <Input name='telefone' label='Telefone' mask typeMask='telefone' maxLength={15} />
          </FieldContainer>
          <FieldContainer>
            <Input name='cep' label='CEP' mask typeMask='cep' maxLength={9} blur={getLocate} />
          </FieldContainer>
          <FieldContainer>
            <Input name='cidade' label='Municipio' />
          </FieldContainer>
          <FieldContainer>
            <Input name='bairro' label='Bairro' />
          </FieldContainer>
          <FieldContainer half>
            <Input name='logradouro' label='Logradouro' />
          </FieldContainer>
          <FieldContainer>
            <Input name='numero' label='Nº' />
          </FieldContainer>
          <FieldContainer >
            <Input name='complemento' label='Complemento' />
          </FieldContainer>
          <AttachmentArea>
            <TitleArea>Cópia legível do RG e CPF ou CNH.</TitleArea>
            <Dropzone
              name='copiaCpf'
              textDrop='Arraste e solte aqui ou clique para selecionar os anexos'
              maxFiles={2}
            />
          </AttachmentArea>
          <AttachmentArea>
            <TitleArea>Comprovante de endereço do estabelecimento. (faturas de energia elétrica, de fornecimento de água, de internet, de telefone ou comprovante de alvará de localização e funcionamento)</TitleArea>
            <Dropzone
              name='comprovanteResidencia'
              textDrop='Arraste e solte aqui ou clique para selecionar os anexos'
            />
          </AttachmentArea>
          <AttachmentArea>
            <TitleArea>Anexar cópia do Cadastro Nacional de Pessoas Jurídicas (CNPJ) atualizado.</TitleArea>
            <Dropzone
              name='copiaCNPJ'
              textDrop='Arraste e solte aqui ou clique para selecionar os anexos'
            />
          </AttachmentArea>
          <AttachmentArea>
            <TitleArea>Anexar cópia do Registro Público de Empresas Mercantis e Atividades Afins da Junta Comercial do Estado de Mato Grosso do Sul (JUCEMS) – Registro da JUNTA COMERCIAL para as Micro Empresas que possuírem e para MEI o Certificado de MEI.</TitleArea>
            <Dropzone
              name='registroPublico'
              textDrop='Arraste e solte aqui ou clique para selecionar os anexos'
            />
          </AttachmentArea>
          <AttachmentArea>
            <TitleArea>Comprovante(s) de funcionamento durante o lapso temporal de 19 de março de 2019 a 19 de março de 2020.*</TitleArea>
            <Dropzone
              name='comprovanteAtividade'
              textDrop='Arraste e solte aqui ou clique para selecionar os anexos'
              maxFiles={5}
            />
          </AttachmentArea>
          <AttachmentArea>
            <TitleArea>Comprovante de cadastro do CADASTUR. (obrigatório para os CNAEs 7911200, 7912100 e 8230001)</TitleArea>
            <Dropzone
              name='certificadoCadastur'
              textDrop='Arraste e solte aqui ou clique para selecionar os anexos'
            />
          </AttachmentArea>
          <AttachmentArea>
            <TitleArea>Cópia legível do cartão magnético ou do contrato de prestação de serviço bancário, que contenha os dados bancários relativos aos números da conta corrente e da agência e ao nome do titular.</TitleArea>
            <Dropzone
              name='copiaCartao'
              textDrop='Arraste e solte aqui ou clique para selecionar os anexos'
            />
          </AttachmentArea>
          <FieldContainer third>
            <SelectSearch
              name='bancoCodigo'
              label='Banco'
              dataSelect={bancos}
            />
          </FieldContainer>
          <FieldContainer third>
            <SelectSearch name='bancoTipoConta' label='Tipo de Conta' dataSelect={[
              { label: `Pessoa Física`, value: 'PF' },
              { label: `Pessoa Jurídica`, value: 'PJ' }
            ]}
            />
          </FieldContainer>
          <FieldContainer third>
            <SelectSearch name='bancoModalidade' label='Modalidade da Conta' dataSelect={[
              { label: `Conta Corrente`, value: 'Conta Corrente' },
              { label: `Poupança`, value: 'Poupança' }
            ]}
            />
          </FieldContainer>
          <FieldContainer full>
            <GroupField>
            <AgencyContainer>
                <div>
                  <Input name='bancoAgencia' label='Agência**' maxLength={6} mask typeMask='agencia' />
                </div>
              </AgencyContainer>
              <AgencyContainer>
                <div>
                  <Input name='bancoContaCorrente' label='Conta Corrente**' mask typeMask='conta' maxLength={14} />
                </div>
              </AgencyContainer>
            </GroupField>
          </FieldContainer>
          <FieldContainer full noMargin>
            <TextObs>
              *Anexar cópia(s) de vouchers emitidos, notas fiscais, dentre outros documentos que comprovem o funcionamento, durante o lapso temporal de 19 de março de 2019 a 19 de março de 2020.
            </TextObs>
          </FieldContainer>
          <FieldContainer full noMargin>
            <TextObs>
              **Troque o X por 0, se necessário.
            </TextObs>
          </FieldContainer>
          <FieldContainer full noMargin>
            <TextObs danger>
              Obs.:
              <Ul marginLeft>
                <li>A inscrição não garante direito ao auxílio;</li>
                <li>Em razão do Decreto n. 10.410, de 30 de junho de 2020, que altera a Tabela de Classificação Nacional de Atividades Econômicas (CNAEs) preponderantes de que trata o Anexo V do Regulamento da Previdência Social, aprovado pelo Decreto n. 3.048, de 6 de maio de 1999, e considerando que as empresas enquadradas como Bares e outros estabelecimentos especializados em servir bebidas (5611202) necessitam promover a revisão de seus CNAEs, sendo necessária a inclusão por outro semelhante, foram inseridas as CNAES 5611-2/04 - Bares e outros estabelecimentos especializados em servir bebidas, sem entretenimento  e  5611-2/05 - Bares e outros estabelecimentos especializados em servir bebidas, com entretenimento, constante na CNAE 2.3;</li>
                <li>Os anexos deverão obrigatoriamente serem no formato Portable Document Format (PDF) ou imagem (JPEG, JPG ou PNG);</li>
                <li> Em caso de dúvidas entre em contato com a Fundação de Turismo de Mato Grosso do Sul (FUNDTUR) através do e-mail fturismo@fundtur.ms.gov.br ou de segunda à sexta-feira pelo telefone (67) 3318-7600 das 07:30 às 12h e 13:30 às 17h.</li>
              </Ul>
            </TextObs>
          </FieldContainer>
          <Divider />
          <ButtonContainer>
            <Button
              text='Inscrever'
              color='primary'
              type='submit'
            />
          </ButtonContainer>
          {modalOptions.open &&
            <Modal title="TERMO DE CONDIÇÕES GERAIS E ACEITE ELETRÔNICO" actions={buttonsModal}>
              <Ul>
                <Li>
                  A participação no Programa "Incentiva+MS Turismo" é condicionada na declaração de renúncia ao direito de futura ação relativa a eventuais indenizações decorrentes de medidas restritivas impostas em razão da emergência em saúde pública causada pela pandemia da Covid-19, e comprovação da desistência de ações com o mesmo teor já propostas em face do Estado, com a correspondente renúncia ao direito veiculado na demanda.
                </Li>
                <Li>
                  A concessão do apoio financeiro emergencial tem caráter temporário e não gera direito adquirido ao seu recebimento e vínculo empregatício com o Estado de Mato Grosso do Sul.
                </Li>
                <Li>
                  Somente será concedido 1 (um) apoio financeiro emergencial por família. Para fins do disposto no § 2º do art. 3º da Lei nº 5.687, de 2021, em que considera-se família a unidade familiar composta por um ou mais indivíduos, eventualmente ampliada por outros indivíduos que possuam laços de parentesco ou de afinidade entre si, que contribuam para o rendimento ou tenham as suas despesas atendidas por aquela unidade familiar, e que tenham o mesmo domicílio.
                </Li>
                <Li>
                  O apoio financeiro emergencial será concedido pelo Poder Executivo e creditado em conta corrente de titularidade do beneficiário.
                </Li>
                <Li>
                  Caso a concessão do apoio financeiro emergencial seja impeditivo ao acesso a benefícios sociais concedidos pela União, o pretenso beneficiário deverá optar, expressamente, pela adesão ao Programa Incentiva+MS Turismo, assumindo por sua conta e risco, eventual exclusão da participação em Programas Federais ou restrição de acesso, caso já beneficiado.
                </Li>
                <Li>
                  Que esta inscrição não me dará o direito imediato ao apoio financeiro emergencial, pois, passará por análises aos critérios estabelecidos na Lei Estadual nº 5.687, de 7 de julho de 2021, no DECRETO Nº 15.727, DE 14 DE JULHO DE 2021 e nos regulamentos expedidos pela FUNDTUR.
                </Li>
                <Li>
                  Declaro e estou ciente de que apenas um membro da família receberá o apoio financeiro emergencial.
                </Li>
                <Li>
                  Declaro que concordo integralmente com todas as disposições contidas na Lei Estadual nº 5.687, de 7 de julho de 2021 e no DECRETO Nº 15.727, DE 14 DE JULHO DE 2021, me comprometendo a respeitar as condições aqui previstas de forma irretratável. O beneficiário será excluído do Programa Incentiva +MS Turismo se constatadas uma ou mais situações contidas no Art.15 do DECRETO Nº 15.727, DE 14 DE JULHO DE 2021.
                </Li>
              </Ul>
              <FieldContainer full>
                <Checkbox
                  name='ciente'
                  setValue={(value) => setCiente(value)}
                  options={[
                    { label: 'Estou ciente e concordo' }
                  ]}
                />
              </FieldContainer>
            </Modal>}
        </FormStyled>
      </PageBody>
    </Container>
  );
}

export default InscricaoPessoaJuridica;