import styled from 'styled-components';
import { Form } from '@unform/web';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const FormStyled = styled(Form)`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
  padding: 0 20px;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: flex-start;
`

export const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${props => props.noMargin ? '0' : '10px'} 1%;
  width: ${props => props.half ? '47.5%' : props.full ? '100%' : props.third ? '31%' : props.thirdQ ? '73%' :'23%'};

  @media(max-width: 1050px) {
    width: 100%;
  }
`

export const AttachmentArea = styled.div`
  display:flex;
  width: 100%;
  flex-direction: column;
  padding: 10px;
`

export const TitleArea = styled.h4`
  color: ${props => props.theme.PRIMARY};
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-right: 10px;
  margin-top: 10px;
`

export const TextObs = styled.span`
  font-size: 12px;
  color: ${props => props.danger ? props.theme.DANGER : 'black'};
`

export const Ul = styled.ul`
  max-height: 300px;
  overflow-y: ${props => props.marginLeft ? 'none' : 'scroll'};
  list-style-type:disc;
  list-style-position: inside;
  margin-left: ${props => props.marginLeft ? '30px' : 0};
`

export const Li = styled.li`
  margin: 5px 0;
`

export const Divider = styled.hr`
  width: 100%;
  border: none;
  border-top: solid 1px ${props => props.theme.BACKGROUND};
  margin: 10px 0;
`


export const AgencyContainer = styled.div`
  display: flex;
`

export const DigitContainer = styled.div`
  width: 80px;
`

export const GroupField = styled.div`
  display: flex;
  justify-content: space-evenly;
`