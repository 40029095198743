import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import Button from '../../components/Button';
import Card from '../../components/Card';
import Input from '../../components/Input';
import SelectSearch from '../../components/SelectSearch';
import { CardContent, Container, FieldContainer, FormStyled, Img } from './styles';
import LoginStore from '../../stores/LoginStore';
import { useHistory, useLocation } from 'react-router-dom';
import { UserContext } from '../../contexts/user/UserProvider';
import { toast } from 'react-toastify';

function Login() {
  const [dominios, setDominios] = useState([]);
  const { authenticate } = useContext(UserContext);
  const formRef = useRef(null);
  let history = useHistory();
  const params = useQuery()

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  useEffect(() => {
    let login = { ta: params.get('ta'), h: params.get('h') };
    if (login.ta && login.h) {
      const call = async () => {
        await LoginStore.authenticateTaH(login).then(response => {
          if (response.status === 200) {
            if (response.data.statusCode === 0) {
              toast.success("Bem-Vindo")
              authenticate(response.data.data);
              history.push('/home');
            } else {
              toast.error("Usuário/senha ou domínio incorreto.");
            }
          }
          else if (response.status === 401) {
            toast.error(response.data.statusMessage)
          }
        })
      }
      call();
    }

  }, [authenticate, history, params])

  useEffect(() => {
    const call = async () => {
      await LoginStore.domains().then(response => {
        if (response.status === 200) {
          setDominios([{ label: 'Selecione', value: '' }, ...response.data.map(dom => ({ label: dom, value: dom }))]);
        }
        else if (response.status === 204) {
          toast.error('não há dominios cadastrados');
        }
        else
          toast.error(response.data.mensagem);
      })
    }

    call();
  }, []);

  const handleSubmit = useCallback(async (data) => {
    formRef.current?.setErrors({});
    let error = false;

    if (data.dominio === '') {
      formRef.current?.setFieldError("dominio", "Campo obrigatório")
      error = true;
    }
    if (data.usuario === '') {
      formRef.current?.setFieldError("usuario", "Campo obrigatório")
      error = true;
    }
    if (data.senha === '') {
      formRef.current?.setFieldError("senha", "Campo obrigatório")
      error = true;
    }

    if (error) {
      return;
    }

    await LoginStore.authenticate(data).then(response => {
      if (response.status === 200) {
        toast.success('Bem vindo!');
        authenticate(response.data);
        history.push('/home');
      }
      else
        toast.error(response.data);
    })

  }, [history, authenticate]);

  const enter = () => {
    formRef.current.submitForm();
  }
  return (
    <Container>
      <Card fit>
        <CardContent>
          <Img src='/IncentivaMais.svg' />
          <FormStyled ref={formRef} onSubmit={handleSubmit}>
            <FieldContainer full>
              <SelectSearch name='dominio' label='Dominio' dataSelect={dominios} />
            </FieldContainer>
            <FieldContainer full>
              <Input name='usuario' label='Usuário' enter={enter} />
            </FieldContainer>
            <FieldContainer full>
              <Input name='senha' label='Senha' type='password' enter={enter} />
            </FieldContainer>
            <FieldContainer full>
              <Button
                text="entrar"
                noMargin
                type='submit'
              />
            </FieldContainer>
          </FormStyled>
        </CardContent>
      </Card>
    </Container>
  );
}

export default Login;