import React from 'react';
import { Container } from './styles';

function Card({ children, fit = false }) {
  return (
    <Container fit={fit}>
      {children}
    </Container>
  );
}

export default Card;