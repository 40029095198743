import React, { useCallback, useEffect, useRef, useState } from 'react';
import Button from '../../components/Button';
import DataTable from '../../components/DataTable';
import Input from '../../components/Input';
import TemplatePage from '../../components/TemplatePage';
import { Container, Content, FormStyled, InputContainer, Title } from './styles';
import BancoStore from '../../stores/BancoStore';
import { toast } from 'react-toastify';
import Modal from '../../components/Modal';

function Bancos() {
  const formRef = useRef(null);
  const [bancos, setBancos] = useState([]);
  const [filtros, setFiltros] = useState({});
  const [totalPaginas, setTotalPaginas] = useState(0);
  const [totalRegistro, setTotalRegistro] = useState(0);
  const [param, setParam] = useState({ pagina: 1, registroPorPagina: 15, ordernarPor: 'desc' });
  const [crud, setCrud] = useState({ title: 'Criar Banco', disabled: false, buttonText: 'Criar', create: true, codigo: 0 });
  const [modalOptions, setModalOptions] = useState({ open: false });

  const colunas = [
    { name: 'Código', property: 'codigo', sortable: false, width: '20%' },
    { name: 'Nome', property: 'nome', width: '60%', sortable: false },
  ];

  const openModal = (banco) => {
    setModalOptions({ open: true, banco: banco })
  }

  const getBanco = useCallback(async (id) => {
    await BancoStore.getByCodigo(id).then(response => {
      if (response.status === 200) {
        formRef.current.setFieldValue('codigo', response.data.codigo)
        formRef.current.setFieldValue('nome', response.data.nome)
      }
      else {
        toast.error(response.data.mensagem);
      }
    })
  }, [])

  const viewBanco = useCallback(async (banco) => {
    await getBanco(banco.codigo);
    await setCrud({ title: `Visualizar banco ${banco.codigo}`, disabled: true, buttonText: '', create: false, codigo: banco.codigo })
  }, [getBanco]);

  const editBanco = useCallback(async (banco) => {
    await getBanco(banco.codigo);
    await setCrud({ title: `Editar banco ${banco.codigo}`, disabled: false, buttonText: 'Editar', create: false, codigo: banco.codigo })
  }, [getBanco]);

  const cancelButton = useCallback(() => {
    formRef.current.reset();
    setCrud({ title: 'Criar banco', disabled: false, buttonText: 'Criar', create: true, id: 0 });
  }, [formRef])

  const acao = [
    { text: '', iconName: 'FaRegEye', color: 'background', click: viewBanco, return: true, tooltip: 'Visualizar' },
    { text: '', iconName: 'FaEdit', color: 'warning', iconColor: 'dark', click: editBanco, return: true, tooltip: 'Editar' },
    { text: '', iconName: 'FaTimes', color: 'danger', iconColor: 'light', click: openModal, return: true, conditionalStatus: true, tooltip: 'Inativar' },
    { text: '', iconName: 'FaCheck', color: 'success', iconColor: 'light', click: openModal, return: true, conditionalStatus: false, tooltip: 'Ativar' },
  ];

  useEffect(() => {
    const call = async () => {
      await BancoStore.get({ ...param, ...filtros }).then(response => {
        if (response.status === 200) {
          setBancos(response.data.lista);
          setTotalPaginas(response.data.totalDePaginas);
          setTotalRegistro(response.data.totalDeRegistros);
        }
        else {
          toast.error(response.data.mensagem);
        }
      })
    }

    call();
  }, [filtros, param])

  const submit = useCallback(async (data) => {
    if (crud.create) {
      await BancoStore.create(data).then(response => {
        if (response.status === 201) {
          cancelButton();
          toast.success(response.data.mensagem);
          setParam({ ...param, pagina: 1 });
        }
        else
          toast.error(response.data.mensagem);
      })
    }
    else {
      await BancoStore.put(crud.codigo, data).then(response => {
        if (response.status === 200) {
          cancelButton();
          toast.success(response.data.mensagem);
          setParam({ ...param, pagina: 1 });
        }
        else
          toast.error(response.data.mensagem);
      })
    }

  }, [crud, cancelButton, param])

  const ativarOuInativar = useCallback(async (banco) => {
    BancoStore.ActiveOrInactive(banco.codigo, !banco.ativo).then(response => {
      if (response.status === 200) {
        toast.success(response.data.mensagem);
        setParam({ ...param, pagina: 1 })
      }
      else
        toast.error(response.data.message);

      setModalOptions({ open: false });
    })
  }, [param])

  const buttonsModalInativar = [
    <Button text="cancelar" click={() => setModalOptions({ open: false })} color="background"></Button>,
    <Button text="Inativar" click={() => ativarOuInativar(modalOptions.banco)} color="danger"></Button>,
  ];

  const buttonsModalAtivar = [
    <Button text="cancelar" click={() => setModalOptions({ open: false })} color="background"></Button>,
    <Button text="Ativar" click={() => ativarOuInativar(modalOptions.banco)} color="success"></Button>,
  ];

  function handlePageChange(params) {
    if (param.pagina === params)
      return;
    setParam({ ...param, pagina: params });
  }

  return (
    <TemplatePage title='Bancos'>
      <Container>
        <Content>
          <FormStyled ref={formRef} onSubmit={submit}>
            <Title>{crud.title}</Title>
            <InputContainer>
              <Input name='codigo' disabled={!crud.create} label='Código' />
            </InputContainer>
            <InputContainer>
              <Input name='nome' disabled={crud.disabled} label='Nome' />
            </InputContainer>
            {!crud.title.includes('Visualizar') && <Button text={crud.buttonText} type='submit' />}
            {!crud.create && <Button text='cancelar' color='background' click={cancelButton} />}
          </FormStyled>
          <DataTable
            columns={colunas}
            actions={acao}
            data={bancos}
            pageChange={handlePageChange}
            allPages={totalPaginas}
            allRegister={totalRegistro}
            rowNumber={param.registroPorPagina}
            handleSort={(column, desc) => setParam({ ...param, ordernarPor: desc })}
            desc={param.ordernarPor}
            page={param.pagina}
          />
          {modalOptions.open && <Modal title={`${modalOptions.banco.ativo ? 'Inativar' : 'Ativar'} banco`} actions={modalOptions.banco.ativo ? buttonsModalInativar : buttonsModalAtivar}>
            <span>Deseja {modalOptions.banco.ativo ? 'inativar' : 'ativar'} este banco?</span>
          </Modal>}
        </Content>
      </Container>
    </TemplatePage>
  );
}

export default Bancos;