import React, { useEffect, useState } from 'react';
import { lightTheme } from '../../styles/theme';
import TooltipText from '../TooltipText';
import { ButtonTag, TextArea } from './styles';

function Button({ children, click, color = 'primary', text = null, minify = false, type = 'button',
  tooltip = null, disabled = false, noMargin = false, rounded = false }) {
  const [dark, setDark] = useState(false);

  useEffect(() => {
    let hexColor = lightTheme[color.toUpperCase()];
    if (hexColor) {
      hexColor = hexColor.replace('#', '');
      const rgb = [hexColor.substring(0, 2), hexColor.substring(2, 4), hexColor.substring(4, 6)];
      var sum = Math.round(((parseInt(rgb[0], 16) * 299) + (parseInt(rgb[1], 16) * 587) + (parseInt(rgb[2], 16) * 114)) / 1000);
      setDark((sum > 128) ? true : false);
    }
  }, [color])

  return (
    <ButtonTag
      onClick={click}
      colorType={color.toUpperCase()}
      dark={dark}
      minify={minify}
      type={type}
      disabled={disabled}
      noMargin={noMargin}
      rounded={rounded}
    >
      {children}
      {text ? <TextArea dark={dark}>{text}</TextArea> : null}
      {tooltip &&
        <TooltipText>
          {tooltip}
        </TooltipText>}
    </ButtonTag>
  );
}

export default Button;