import React, { useCallback, useEffect, useRef, useState } from 'react';
import Button from '../../components/Button';
import DataTable from '../../components/DataTable';
import Input from '../../components/Input';
import TemplatePage from '../../components/TemplatePage';
import { CardCounter, Container, Content, CounterArea, CounterCard, FieldContainer, FormStyled } from './styles';
import EdicaoStore from '../../stores/EdicaoStore';
import { toast } from 'react-toastify';
import Modal from '../../components/Modal';

function Classificacao() {
  const [habilitados, setHabilitados] = useState([]);
  const [modalOptions, setModalOptions] = useState({ open: false });
  const formRef = useRef(null);
  const [totalPaginas, setTotalPaginas] = useState(0);
  const [totalRegistro, setTotalRegistro] = useState(0);
  const [habilitado, setHabilitado] = useState(0);
  const [classificado, setClassificado] = useState(0);
  const [restante, setRestante] = useState(0);
  const [param, setParam] = useState({ pagina: 1, registroPorPagina: 30, ordernarPor: 'Aguardando' });

  function handlePageChange(params) {
    if (param.pagina === params)
      return;
    setParam({ ...param, pagina: params });
  }

  const colunas = [
    { name: '', property: 'tipo', width: '4%', sortable: false },
    { name: 'Nome / Nome Fantasia', property: 'nome', sortable: false, width: '60%', filtered: true },
    { name: 'CPF / CNPJ', property: 'cpfCnpj', sortable: false, width: '20%', filtered: true },
  ];

  useEffect(() => {
    getClassificacao();
  }, [param])

  const gerarClassificador = useCallback(async (data) => {
    await EdicaoStore.createRank(data.qtdClassificado).then(response => {
      if (response.status === 200) {
        toast.success(response.data)
        getClassificacao();
      }
      else {
        toast.error(response.data.mensagem);
      }
    })
  }, [])

  const finalizarClassificador = useCallback(async () => {
    setModalOptions({ open: false })
    await EdicaoStore.finish().then(response => {
      if (response.status === 200) {
        toast.success(response.data)
      }
      else {
        toast.error(response.data.mensagem);
      }
    })
  }, [])

  const getClassificacao = useCallback(async (data) => {
    await EdicaoStore.get(param).then(response => {
      if (response.status === 200) {
        setHabilitados(response.data.lista);
        setTotalPaginas(response.data.totalDePaginas);
        setTotalRegistro(response.data.totalDeRegistros);
        setHabilitado(response.data.totalHabilitados);
        setClassificado(response.data.totalClassificados);
        setRestante(response.data.totalAClassificar);
      }
      else {
        toast.error(response.data.mensagem);
      }
    })
  }, [param])

  const buttonsModal = [
    <Button text="não" click={() => setModalOptions({ open: false })} color="background"></Button>,
    <Button text="sim" click={finalizarClassificador} color="success"></Button>,
  ]

  return (
    <TemplatePage title='Classificação'>
      <Container>
        <Content>
          <FormStyled ref={formRef} onSubmit={gerarClassificador}>
            <FieldContainer>
              <Input name='qtdClassificado' label='Quantidade de classificados' type='number' />
            </FieldContainer>
            <Button
              color='primary'
              text='gerar'
              noMargin
              type='submit'
            />
          </FormStyled>
          <CounterArea>
            <CounterCard colorBg='light'>
              habilitados
              <CardCounter colorBg='dark'>
                {habilitado}
              </CardCounter>
            </CounterCard>
            <CounterCard colorBg='success'>
              classificados
              <CardCounter colorBg='light'>
                {classificado}
              </CardCounter>
            </CounterCard>
            <CounterCard colorBg='warning'>
              restantes
              <CardCounter colorBg='light'>
                {restante}
              </CardCounter>
            </CounterCard>
          </CounterArea>
          <DataTable
            columns={colunas}
            data={habilitados}
            pageChange={handlePageChange}
            allPages={totalPaginas}
            allRegister={totalRegistro}
            rowNumber={param.registroPorPagina}
            handleSort={(column, desc) => setParam({ ...param, ordernarPor: desc })}
            desc={param.ordernarPor}
            page={param.pagina}
          />
          <div style={{ display: 'flex', justifyContent: 'center', margin: 20 }}>
            <Button
              color='primary'
              text='finalizar classificação'
              click={() => setModalOptions({ open: true })}

            />
          </div>
        </Content>
        {modalOptions.open &&
          <Modal title="Finalizar classificação" actions={buttonsModal}>
            Deseja finalizar a classificação gerada?
          </Modal>}
      </Container>
    </TemplatePage>
  );
}

export default Classificacao;