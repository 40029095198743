import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  position: fixed;
  z-index: 5;
  padding: 20px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0,0,0,0.4);
`;
