import Api from '../functions/api';
// import { serialize } from '../functions/objectToQuery';

const getLocate = (cep) => {
  return Api.get(`/Localidade/cep/${cep}`);
}

const methods = {
  getLocate,
}

export default methods;