import React from 'react';
import { BodyModal, ButtonContainer, Container, Content, Divider, Header } from './styles';

function Modal({ children, title = '', actions = [] }) {
  return (
    <Container>
      <Content>
        <Header>
          <h4>{title}</h4>
        </Header>
        <Divider />
        <BodyModal>
          {children}
        </BodyModal>
        <Divider />
        <ButtonContainer>
          {actions.map(action => action)}
        </ButtonContainer>
      </Content>
    </Container>
  );
}

export default Modal;