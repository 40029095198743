import React from 'react';
import { useHistory } from 'react-router-dom';
import Button from '../Button';
import Icon from '../Icon';
import { ButtonArea, Container, Title } from './styles';

function TemplatePage({ children, title = '' }) {
  let history = useHistory();
  return (
    <Container>
      <ButtonArea>
        <Button minify color="background" click={() => history.goBack()} rounded>
          <Icon iconName="FaLongArrowAltLeft" size={16} color='DARK' />
        </Button>
        <Title>
          {title}
        </Title>
      </ButtonArea>
      {children}
    </Container>
  );
}

export default TemplatePage;