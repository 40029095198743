import styled from 'styled-components';
import { Form } from '@unform/web';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const FieldContainer = styled.div`
  display: flex;
  width: 300px;
  margin-bottom: 5px;
  margin-right: 5px;
`

export const Table = styled.table`
  padding: 5px;
  border-collapse: collapse;
  width: 100%;
`

export const TableRow = styled.tr`
  height: 50px;
  background-color: ${props => props.theme.TITLE};
`

export const TableHead = styled.th`
  font-size: 18px;
  color: white;
  line-height: 1.2;
  font-weight: unset;
  width: ${props => props.largura};
  &:nth-child(1){
    padding-left:5px;
    border-top-left-radius: 5px;
  }
  &:nth-last-child(1){
    border-top-right-radius: 5px;
  }
`

export const TableRowBody = styled.tr`
  height: 40px;
  color: darkslategray;
  font-size: 18px;
  font-weight: unset;
  &:nth-child(even) {
    background-color: #f9f9f9;
  }
  &:nth-child(odd) {
    background-color: #fcfcfc;
  }
  &:hover {
    color: #555;
    background-color: #f4f4f4;
  }
`

export const Td = styled.td`
  ${props => props.icon ? 'text-align: center;' : null}
  ${props => props.icon ? 'vertical-align: middle;' : null}
  
  ;
  &:nth-child(1){
    padding-left: 5px;
  }
  &:nth-last-child(1){
    padding-right: 5px;
  }
`

export const ColorTd = styled.div`
  height: 20px;
  width: 10px;
  background-color: ${props => props.theme[props.colorBg?.toUpperCase()]};
`

export const ActionArea = styled.div`
  display: flex;
  justify-content: ${props => props.header ? 'flex-start' : 'center'};
  align-items: center;
  flex-wrap: wrap;
`

export const PaginationArea = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0px 20px;
  align-items: center;
  height: 50px;
  background-color: ${props => props.theme.TITLE};
  color: white;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
`

export const CounterArea = styled.div`

`

export const PagesArea = styled.div`
  display: flex;
`

export const PageField = styled.input`
  width: 30px;
`

export const ButtonPagination = styled.button`
  width: 30px;
  height: 30px;
  border: none;
  color: ${props => props.theme.LIGHT};
  background-color: ${props => props.theme.TITLE};
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: ease 100ms;

  &:focus {
    outline: 0;
  }

  &:hover {
    background-color: ${props => props.theme.TITLE_DARKER};
    color: ${props => props.theme.PRIMARY};
  }
`

export const TopArea = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
`

export const FormStyled = styled(Form)`
  display: flex;
  align-items: flex-end;
  width: 100%;
  padding-top: 10px;
`