import { Form } from '@unform/web';
import styled from 'styled-components';

export const Container = styled.div`
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url('/caverna.jpg') ;
  background-size: cover;
`

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 300px;
  align-items: center;
`

export const Logo = styled.h3`
  font-size:large;
  color: ${props => props.theme.SECONDARY};
  background-color: ${props => props.theme.TITLE};
  padding: 10px;
  margin: 20px 0;
  border-radius: 5px;
  width: fit-content;
`

export const Img = styled.img`
  height: ${props => props.smaller ? '30px' : '50px'};
  margin: ${props => props.noMargin ? '0' : '10px 5px'};
`

export const FormStyled = styled(Form)`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
  padding: 0 20px;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: flex-start;
`

export const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${props => props.noMargin ? '0' : '5px'} 1%;
  width: ${props => props.half ? '47.5%' : props.full ? '100%' : props.third ? '31%' : props.thirdQ ? '73%' :'23%'};

  @media(max-width: 1050px) {
    width: 100%;
  }
`