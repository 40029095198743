import React from 'react';
import {
  Page, Text, View, Document, StyleSheet, Image
} from '@react-pdf/renderer';
import moment from 'moment';

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: 'white',
    padding: 10,
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1
  },
  images: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    position: 'relative',
    marginTop: 10,
  },
  logoIncentiva: {
    marginTop: 20,
  },
  protocoloArea: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: 20,
    marginBottom: 20,
  },
  protocolo: {
    padding: '10px 20px',
    backgroundColor: '#D9EFEF',
    color: '#009696',
    borderRadius: 10,
    fontWeight: 700,
  },
  textSpace: {
    marginBottom: 10,
    fontSize: 14,
    padding: '0 35px 0 15px',
  },
  centerLink: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    marginTop: 20,
    marginBottom: 20
  },
  colorLink: {
    color: '#009696',
    fontSize: 14,
    fontWeight: 700
  },
  bold: {
    fontWeight: 700,
  },
  logoIncentivaMais: {
    width: 220,
    height: 82,
    marginBottom: 20
  },
  dtCriacao: {
    position: 'absolute',
    right: 5,
    bottom: 5,
    fontSize: 14,
  }
});

function PDFProtocol({ protocolo = '', pf = false }) {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.images}>
          <View style={styles.logoIncentiva}>
            <Image src='/IncentivaMais.png' style={styles.logoIncentivaMais} />
          </View>
        </View>
        <Text style={styles.textSpace}>
          Obrigado por participar do Programa Estadual <Text style={styles.bold}>Incentiva+MS Turismo.</Text>
        </Text>
        <Text style={styles.textSpace}>
          O programa tem o objetivo de conceder apoio financeiro emergencial a pessoas físicas que desenvolvem atividade de Guia de turismo, a Microempreendedores Individuais (MEIs) e a Microempresas (MEs), na forma que menciona, afetados pelas adversidades econômicas decorrentes da pandemia da Covid-19, conforme LEI Nº 5.687, DE 7 DE JULHO DE 2021.
        </Text>
        <View style={styles.protocoloArea}>
          <Text style={styles.protocolo}>{protocolo}</Text>
        </View>
        <Text style={styles.textSpace}>
          Este é o número do seu protocolo. Acompanhe o andamento da sua solicitação através do Diário Oficial MS www.spdo.ms.gov.br/diariodoe
        </Text>
        <Text style={styles.textSpace}>
          • A participação no Programa "Incentiva+MS Turismo" é condicionada na declaração de renúncia ao direito de futura ação relativa a eventuais indenizações decorrentes de medidas restritivas impostas em razão da emergência em saúde pública causada pela pandemia da Covid-19, e comprovação da desistência de ações com o mesmo teor já propostas em face do Estado, com a correspondente renúncia ao direito veiculado na demanda.
        </Text>
        <Text style={styles.textSpace}>
          • A concessão do apoio financeiro emergencial tem caráter temporário e não gera direito adquirido ao seu recebimento e vínculo empregatício com o Estado de Mato Grosso do Sul.
        </Text>
        <Text style={styles.textSpace}>
          • Somente será concedido 1 (um) apoio financeiro emergencial por família. Para fins do disposto no § 2º do art. 3º da Lei nº 5.687, de 2021, em que considera-se família a unidade familiar composta por um ou mais indivíduos, eventualmente ampliada por outros indivíduos que possuam laços de parentesco ou de afinidade entre si, que contribuam para o rendimento ou tenham as suas despesas atendidas por aquela unidade familiar, e que tenham o mesmo domicílio.
        </Text>
        <Text style={styles.textSpace}>
          • O apoio financeiro emergencial será concedido pelo Poder Executivo e creditado em conta corrente de titularidade do beneficiário.
        </Text>
        <Text style={styles.textSpace}>
          • Caso a concessão do apoio financeiro emergencial seja impeditivo ao acesso a benefícios sociais concedidos pela União, o pretenso beneficiário deverá optar, expressamente, pela adesão ao Programa Incentiva+MS Turismo, assumindo por sua conta e risco, eventual exclusão da participação em Programas Federais ou restrição de acesso, caso já beneficiado.
        </Text>
        <Text style={styles.textSpace}>
          • Que esta inscrição não me dará o direito imediato ao apoio financeiro emergencial, pois, passará por análises aos critérios estabelecidos na Lei Estadual nº 5.687, de 7 de julho de 2021, no DECRETO Nº 15.727, DE 14 DE JULHO DE 2021 e nos regulamentos expedidos pela FUNDTUR.
        </Text>
        <Text style={styles.textSpace}>
          • Declaro e estou ciente de que apenas um membro da família receberá o apoio financeiro emergencial.
        </Text>
        {pf && <View style={styles.textSpace}>
          <Text>• Declaro que não possuo emprego formal ativo na iniciativa privada;</Text>
          <Text>- que não sou pessoa detentora de cargo, emprego ou função públicos;</Text>
          <Text>- que não sou titular de benefício previdenciário;</Text>
          <Text>- que não estou recebendo seguro desemprego.</Text>
        </View>}
        <Text style={styles.textSpace}>
          • Declaro que concordo integralmente com todas as disposições contidas na Lei Estadual nº 5.687, de 7 de julho de 2021 e no DECRETO Nº 15.727, DE 14 DE JULHO DE 2021, me comprometendo a respeitar as condições aqui previstas de forma irretratável. O beneficiário será excluído do Programa Incentiva +MS Turismo se constatadas uma ou mais situações contidas no Art.15 do DECRETO Nº 15.727, DE 14 DE JULHO DE 2021.
        </Text>
        <Text style={styles.textSpace}>
        Em caso de dúvidas entre em contato com a Fundação de Turismo de Mato Grosso do Sul (FUNDTUR) através do e-mail fturismo@fundtur.ms.gov.br ou de segunda à sexta-feira pelo telefone (67) 3318-7600 das 07:30 às 12h e 13:30 às 17h.
        </Text>
        <Text style={styles.dtCriacao}>data de criação: {moment(new Date()).format('DD/MM/yyyy hh:mm:ss')}</Text>
      </Page>
    </Document >
  );
}

export default PDFProtocol;