import { hot } from 'react-hot-loader/root';
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import Routes from "./routes/Routes";
import GlobalStyle, { Container } from "./styles/globalStyle";
import { lightTheme } from './styles/theme'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loading from './components/Loading';
import { UserProvider } from './contexts/user/UserProvider'
import { FiltersProvider } from './contexts/filters/FiltersProvider';

function App() {
  return (
    <Container>
      <ThemeProvider theme={lightTheme}>
        <UserProvider>
          <FiltersProvider>
            <GlobalStyle />
            <BrowserRouter>
              <Routes />
            </BrowserRouter>
            <ToastContainer
              pauseOnFocusLoss={false}
              autoClose={3000}
            />
            <Loading />
          </FiltersProvider>
        </UserProvider>
      </ThemeProvider>
    </Container>
  );
}

export default hot(App);
