export const lightTheme = {
  PRIMARY: '#374c97',
  PRIMARY_DARKER: '#1a2f3c',
  SECONDARY: '#D9DD50',
  SECONDARY_DARKER: '#c9981c',
  INFO: '#4179d1',
  INFO_DARKER: '#3c6fc1',
  SUCCESS: '#05a300',
  SUCCESS_DARKER: '#036500',
  DANGER: '#c30000',
  DANGER_DARKER: '#820000',
  WARNING: '#FFA502',
  WARNING_DARKER: '#eb9801',
  LIGHT: '#fff',
  LIGHT_DARKER: '#e7e7e7',
  BACKGROUND: '#d3d3d3',
  BACKGROUND_DARKER: '#c3c2c2',
  DARK: '#000',
  DARK_DARKER: '#000',
  TITLE: '#215240',
  TITLE_DARKER: '#193d2f',
  EMPRESA: '#70AD47',
  GUIA: '#F6C34F',
  TEXT_GRAY: '#777777'
}