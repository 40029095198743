import axios from 'axios';
import { trackPromise } from 'react-promise-tracker';

const api = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_API_URL,
    headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    }
})

const get = (param, config) => trackPromise(api.get(param, config));
const post = (param, body, config) => trackPromise(api.post(param, body, config));
const put = (param, body, config) => trackPromise(api.put(param, body, config));
const del = (param) => trackPromise(api.delete(param));

const getToken = async (request) => {
    const body = `client_id=${process.env.REACT_APP_CLIENT}&grant_type=client_credentials&client_secret=${process.env.REACT_APP_SECRET}`
    const url = process.env.REACT_APP_BASEURL_TOKEN ?? ""
    const response = await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        body
    });
    const data = await response.json()
    request.headers['Authorization'] = 'Bearer ' + data.access_token;

    window.localStorage.setItem('token', 'Bearer ' + data.access_token);
    return request;
}

api.interceptors.response.use((response) => {
    return response;
}, async (err) => {
    if (err?.response) {
        if (err.response && err.response.status === 401 && (err.response.data.message || err.response.data.exp)) {
            let originalReq = err.config;

            originalReq._retry = true;

            originalReq = await getToken(originalReq);
            try {
                var response = await axios(originalReq);
                return response;
            }
            catch (e) {
                return e.response
            }
        }
    }
    else
        throw new Error('Erro ao buscar keycloack');

    return err?.response;
});

const exp = {
    get,
    post,
    put,
    del,
    api,
    getToken
};

export default exp;