export const documentacao = (label, pf) => {
  switch (label) {
    case 'CertificadoCadastur':
      return 'Comprovante de cadastro do CADASTUR.'
    case 'ComprovanteResidencia':
      if (pf)
        return 'Comprovante de endereço de residência. (faturas de energia elétrica, de fornecimento de água, de internet, de telefone ou comprovante de domicílio eleitoral)'
      else
        return 'Comprovante de endereço do estabelecimento. (faturas de energia elétrica, de fornecimento de água, de internet, de telefone ou comprovante de alvará de localização e funcionamento)'
    case 'CopiaCNPJ':
      return 'Cópia do Cadastro Nacional de Pessoas Jurídicas (CNPJ) atualizado.'
    case 'RegistroPublico':
      return 'Cópia do Registro Público de Empresas Mercantis e Atividades Afins da Junta Comercial do Estado de Mato Grosso do Sul (JUCEMS) – Registro da JUNTA COMERCIAL para as Micro Empresas que possuírem e para MEI o Certificado de MEI.'
    case 'ComprovanteAtividade':
      return 'Comprovante(s) de funcionamento durante o lapso temporal de 19 de março de 2019 a 19 de março de 2020.'
    case 'Declaracao':
      return 'Cópia da Carteira de Trabalho (CTPS) caso possua'
    case 'CopiaCPF':
      return 'Cópia legível do RG e CPF ou CNH'
    case 'CopiaCartaoBancario':
      return 'Cópia legível do cartão magnético ou do contrato de prestação de serviço bancário, que contenha os dados bancários relativos aos números da conta corrente e da agência e ao nome do titular.'
  }
}