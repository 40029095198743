import { Form } from '@unform/web';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Container = styled.div`
  min-height: 100vh;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
`;

export const CardForm = styled(Form)`
  text-decoration: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${props => props.theme.LIGHT};
  width: 230px;
  height: 260px;
  border-radius: 5px;
  overflow: hidden;
`

export const CardMenu = styled(Link)`
  text-decoration: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${props => props.theme.LIGHT};
  width: 230px;
  border-radius: 5px;
  overflow: hidden;
`

export const CardTitle = styled.h3`
  width: 100%;
  background-color: ${props => props.theme[props.colorBg?.toUpperCase()]};
  text-align: center;
  text-transform: uppercase;
  padding: 15px 0;
  color: ${props => props.theme.LIGHT};
  margin-bottom: 20px;
`

export const ButtonCard = styled.h2`
  width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${props => props.theme.LIGHT};
  border-radius: 3px;
  padding: 10px;
  margin: 20px 0;
  background-color: ${props => props.theme[props.colorTxt?.toUpperCase()]};
  box-shadow: 0px 10px 9px -7px #000000, 5px 5px 15px 5px rgba(0,0,0,0);
  cursor: pointer;
`

export const CardBody = styled.div`
  padding: 0 20px;
  display:flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
`